.Tabs {

  &-item {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity .6s, transform .6s;
    transform: translateY(20px);
    &.is-active {
      overflow: visible;
      height: auto;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-content {
    @include spacer("p", "t", 6);

    @include media-breakpoint-up(sm) {
      @include spacer("p", "t", 7);
    }
  }

  // @include media-breakpoint-up(sm) {
    // &:not(.is-active) {
    //   .Tabs-item:first-child {
    //     height: auto;
    //     overflow: visible;
    //     opacity: 1;
    //     transform: translateY(0);
    //   }
    // }
  // }

}
