.TeamSlider {
  position: relative;

  .swiper-container {
    overflow: inherit;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  .swiper-wrapper {
    align-items: center;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;
  }

  .swiper-slide {
    margin-right: 30px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      margin-right: 60px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      margin-right: 90px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin-right: 150px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .TeamMember {
    opacity: 0;
    transition: opacity .1s;
  }

  &.is-init .TeamMember {
    opacity: 1;
  }


  // horizontal canvas
  #team-dharma,
  #team-patrizio,
  #team-valerio,
  #team-giorgio {
    width: 85%;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      width: 56%;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      width: 42%;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: 50%;
    }
  }

  // vertical canvas
  #team-michele,
  #team-romina,
  #team-luisa,
  #team-gianmarco {
    width: 70%;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      width: 42%;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      width: 34%;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: 42%;
    }
  }
}

