.TextWMedia {

  $c : &;

  .row {
    align-items: flex-start;
  }

  &-text,
  &-mediaList {
    @include make-col-ready();
  }

  &-text {
    > :last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
    // @include media-breakpoint-up(md) {
    //   @include make-col(5);
    // }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
      @include make-col-offset(1);
      .TextWMedia--reverseOrder & {
        // @include make-col-offset(2);
      }
    }

    #{$c}--hasMedia & {
      @include spacer-stack(5);
      @include media-breakpoint-up(sm) {
        @include spacer-stack(0);
        @include spacer("p", "y", 6);
      }
    }
  }

  &-media {
    --margin-left: 0;
    --margin-right: 0;
    margin-left: var(--margin-left);
    margin-right: var(--margin-right);

    @include media-breakpoint-down(sm) {
      --margin-left: auto;
      --margin-right: auto;
    }

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: $color-background-placeholder;
      opacity: 1;
      transition: opacity .4s;
    }

    &.is-loaded:before {
      opacity: 0;
    }

    & + & {
      @include spacer("m", "t", 3);
      @include media-breakpoint-up(sm) {
        @include spacer("m", "t", 6);
      }
    }

    #{$c}--mobileMedia & {
      max-width: $mobile-media-max-width;

      @include media-breakpoint-up(sm) {
        --margin-left: auto;
      }
    }
  }

  &-mediaList {
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(5);
      @include make-col-offset(1);

      .TextWMedia--reverseOrder & {
        @include make-col-offset(1);
      }
    }
  }

  &-image {
    width: 100%;
    height: auto;
    display: block;
  }

  &-image,
  &-video {
    opacity: 0;
    transition: opacity .4s;
    .is-loaded & {
      opacity: 1;
    }
  }

  // &-video {
  //   opacity: 0;
  //   &.is-visible {
  //     opacity: 1;
  //   }
  // }

  &--reverseOrder {
    #{$c}-text {
      order: 2;
    }

    #{$c}-media {
      @include media-breakpoint-up(sm) {
        --margin-left: 0;
        --margin-right: 0;
      }
    }
  }

  &--stickyText {
    #{$c}-text {
      @supports(position:sticky) {
        @include media-breakpoint-up(sm) {
          position: sticky;
          top: 100px;
          bottom: 0;
        }
        @include media-breakpoint-up(md) {
          top: 110px;
        }
        @include media-breakpoint-up(lg) {
          top: 120px;
        }
        @include media-breakpoint-up(xl) {
          top: 130px;
        }
      }
    }
  }
}
