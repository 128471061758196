.WorkPreview {

  $c : &;


  &-figure {
    display: block;
    height: 0;
    padding-bottom: calc(4 / 5 * 100%);
    position: relative;
    margin: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: 50% 60%;
    transition: transform 1s;
    // transition-timing-function: cubic-bezier("ease");


    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      visibility: hidden;
      transition: visibility 0s;
      transition-delay: 1s;
      background: $color-background-dark;
      .is-shown & {
        visibility: visible;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-background-dark;
      transform: scaleX(0);
      transition: transform 0.7s, opacity .4s linear 1s;
      .is-shown & {
        transform: scaleX(1);
        opacity: 0;
      }
    }

    .is-shown & {
      transform: scaleY(1);
    }


  }


  &-image {

    position: absolute;
    top: -10%;
    left: -10%;
    right: -10%;
    bottom: -10%;
    transform: scale(0.9);
    opacity: 0;
    transition: opacity .4s, transform 10s;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .is-loaded & {
      opacity: 1;
    }
    .desktop a:hover & {
      transform: scale(1.1);
    }

  }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: #f00;
    transition: opacity .6s;
    .desktop a:hover & {
      opacity: .96;
    }
  }

  &-marquee {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin:0;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
    transition: opacity .6s;
    .desktop a:hover & {
      opacity: .98;
    }
    &-text {
      @include ms-responsive(18);
      letter-spacing: -0.01em;
      font-weight: 400;
      padding-right: 4rem;
      display: inline-block;
      transform: translateX(0) translateZ(0);
      animation: marquee 5s linear infinite;
    }
  }

  &-headings {
    // max-width: 50%;
    line-height: 1.2;
  }

  &-headings,
  &-title,
  &-description {
    display: inline-block;
    margin-bottom: 0;
  }

  &-title {
    margin-top: calc(var(--gutter) * 0.5);
  }

  &-description {
    color: $color-text-inverted-muted;
    font-weight: 500;
    transition-delay: 0.2s;
  }
}
