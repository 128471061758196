.Heading {
  line-height: $headings-line-height;
  font-weight: 400;
  letter-spacing: -0.02em;
  @include spacer("m", "t", 5);
  @include spacer("m", "b", 3);
}

.Heading--light {
  font-weight: 400;
}

.Heading--serif {
  font-family: $font-family-serif;
  font-weight: 100;
  line-height: 1.2;
}

.Heading--huge {
  // @include ms-responsive(12);
  font-size: var(--step-8);
}

.Heading--main {
  // @include ms-responsive(9);
  font-size: var(--step-7);
}

.Heading--secondary {
  // @include ms-responsive(7);
  font-size: var(--step-5);
}

.Heading--medium {
  // @include ms-responsive(6);
  font-size: var(--step-4);
}

.Heading--small {
  // @include ms-responsive(5);
  font-size: var(--step-3);
}

.Heading--bump {
  // @include ms-responsive(3);
  font-size: var(--step-2);
}

.Heading--body {
  // @include ms-responsive(1);
  font-size: var(--step-1);
  letter-spacing: 0;
}

.Heading--context {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 1.7;
  @include media-breakpoint-up(xl) {
    font-size: 15px;
  }
}

