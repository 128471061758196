.Polaroid {
  position: relative;
  margin-bottom: 128px;
  // width: 85%;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(xs) {
    width: 85%;
  }

  @include media-breakpoint-up(lg) {
    width: 80%;
  }

  &-wrapper {
    position: relative;
    padding-top: 30%;
    margin-top: -30%;
    margin-bottom: -30%;
  }

  &-view {
    position: sticky;
    width: 100%;
    padding-bottom: 120%;
    height: 0;
    top: 50%;
    margin-top: 60%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.08),
                0px 0px 40px rgba(0,0,0,0.08),
                0px 10px 60px rgba(0,0,0,0.08);
  }

  &-body {
    position: absolute;
    top: 50%;
    left: ceil($grid-gutter-width-base * 2);
    width: 100%;
    transform: translateY(-50%) translateX(100%);
    display: none;

    @include media-breakpoint-up('sm') {
      display: block;
    }
  }

  &-thumb {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: opacity .2s;

    &.show {
      opacity: 1;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 88%;
      transform: translate3d(-50%, -50%, 0);
      margin-top: -8%;
    }
  }

  &-list {
    position: relative;
    opacity: 0.8;
    filter: grayscale(1) contrast(80%);

    &:before,
    &:after {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      overflow: hidden;
      content: '';
      display: block;
    }

    &:before {
      margin-bottom: -180%;
    }

    &:after {
      margin-bottom: 60%;
    }
  }

  &-image {
    position: relative;
    width: 100%;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;

    @include media-breakpoint-up(sm) {
      padding-top: 20%;
      padding-bottom: 20%;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 25%;
      padding-bottom: 25%;
    }

    img {
      width: 70%;
      opacity: 0;
      transition: opacity .2s;

      &.lazyloaded {
        opacity: 1;
      }
    }
  }
}
