.MediaGrid {

  $c: &;

  &-col {
    margin-bottom: calc(#{$grid-gutter-width-base} / 2);
    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width-base;
    }
  }

  &-media {
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: $color-background-placeholder;
      opacity: 1;
      transition: opacity .4s;
    }

    &.is-loaded:before {
      opacity: 0;
    }

    margin:0 auto;
    #{$c}-col--mobileMedia & {
      max-width: $mobile-media-max-width;
    }
  }

  &-image,
  &-video {
    width: 100%;
    height: auto;
  }

  &-image,
  &-video {
    opacity: 0;
    transition: opacity .4s;
    .is-loaded & {
      opacity: 1;
    }
  }

  // &-video {
  //   opacity: 0;
  //   &.is-visible {
  //     opacity: 1;
  //   }
  // }
}
