.custom-control-label {
  &:before,
  &:after {
    top: .15rem;
  }
  &:before {
    border: 1px solid $border-inverted-grey;
  }
}

.custom-control-input {
  width: 1rem;
  height: 1rem;
  left: 0;
  top: .15rem;
}

label.custom-control-label {
  // color: $color-text-inverted-muted;
  // line-height: 1.5;
  font-size: calc(14 / 16 * 100%);
  font-weight: normal;
  @include media-breakpoint-up(xl) {
    font-size: 15px;
  }
  a {
    text-decoration: underline;
  }
}
