.InfoSection {
  @include spacer("p", "t", 7);
  @include spacer("p", "b", 6);

  @include media-breakpoint-up(sm) {
    @include spacer("p", "t", 8);
    @include spacer("p", "b", 7);
  }

  @include media-breakpoint-up(lg) {
    @include spacer("p", "t", 9);
  }
}
