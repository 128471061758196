* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(#fff, .2);
  font-size: var(--step-0);
  // @include font-smoothing("antialiased");
  // @each $breakpoint in map-keys($grid-breakpoints) {
  //   @include media-breakpoint-up($breakpoint) {
  //     font-size: map-get($font-size-root, $breakpoint);
  //   }
  // }
  // to always show a scrollbar and prevent centering jump
  // https://css-tricks.com/snippets/css/force-vertical-scrollbar
  overflow-y:scroll;
  overscroll-behavior-y: none;
}

body {
  overscroll-behavior-y: none;
  &.inverted-nav {
    background-color: $color-background-darker;
  }
  // .page-transitioning-out & {
  //   overflow: hidden;
  // }
  // overflow: hidden;
  // html.first-run & {
  //   overflow: auto;
  // }
}

#barba-wrapper {
  width: 100%;
  opacity: 0;
  transition: opacity .3s;

  html.ready & {
    opacity: 1;
  }
}

.desktop {
  .barba-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .SiteFooter {
      margin-top: auto;
    }
  }
}

svg {
  fill: currentColor;
  vertical-align: middle;
}

input,
button,
textarea {
  outline: none !important;
  appearance: none;
  border-radius: 0;
}

input, textarea {
  // font-weight: 100 !important;
}

label {
  user-select: none;
  display: block;
  &.is-required:not(.custom-control-label) {
    &:after {
      content: ' *';
      font-weight: 300;
    }
  }
}

label, input, textarea {
  // font-weight: bold;
  @include ms-responsive(7);
  letter-spacing: -0.03em;
  margin:0;
}

label {
  font-weight: 300;
}

input, textarea {
  background-color: transparent;
  // font-family: $font-family-serif;
  font-weight: 300;
  flex-grow: 1;
  width: 100%;
  border: none;
  // border-bottom: 1px solid $color-grey-3;
  padding: 0;
  color: $color-text-inverted-muted;
  transition: border-color .3s;
  outline: none;
  &:focus {
    border-color: #fff !important;
  }
  &:not(:placeholder-shown) {
    border-color: $color-grey-3;
  }
  &:placeholder-shown {
    border-color: #333;
  }
}

textarea {
  line-height: 1.2;
  resize: none;
}

fieldset {
  @include media-breakpoint-up(sm) {
    @include spacer-stack(4);
  }
  @include media-breakpoint-up(xl) {
    @include spacer-stack(6);
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #333;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #333;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #333;
}
:-moz-placeholder { /* Firefox 18- */
  color: #333;
  opacity: 1;
}

::selection {
  background: #aaa;
}
::-moz-selection {
  background: #aaa;
}

body.cke_editable {
  @extend .Prose;
  margin: 1rem;
  hr {
    visibility: visible !important;
  }
}

// .no-transition {
//   transition: none !important;
// }
