.HomeHeader {
  overflow-x: hidden;

  @include spacer("p", "t", 7);
  @include spacer("p", "b", 7);

  @include media-breakpoint-up(sm) {
    @include spacer("p", "t", 8);
    @include spacer("p", "b", 8);
  }

  @include media-breakpoint-up(lg) {
    @include spacer("p", "t", 9);
    @include spacer("p", "b", 9);
  }

  // @include media-breakpoint-up(xl) {
  //   @include spacer("p", "t", 10);
  //   @include spacer("p", "b", 10);
  // }

  &-aside {
    @include spacer("m", "t", 3);
    @include media-breakpoint-up(sm) {
      @include spacer("m", "t", 4);
    }
  }

  &-title {
    margin: 0;
    opacity: 0;
    // white-space: balance;
    font-weight: 400;
    max-width: 30ch;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--step-10);

    > div {
      position: relative;
      white-space: nowrap;

      > div {
        position: relative;
        z-index: 3;
        opacity: 0;
      }

      > span {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;

        span {
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          opacity: 0;
          background-color: $color-background-darker;
        }

        &:first-child {
          transform: translateX(-100%);
        }

        &:last-child {
          left: -8px;
        }
      }

      > span {
        color: rgba(#fff, .15);
      }

      > div {
        color: #fff;
      }
    }

    // max-width: 18rem;

    @media (min-width: 370px) {
      // max-width: 22rem;
      br {
        display: none;
      }
    }

    @include media-breakpoint-up(sm) {
      // width: 32rem;
      // max-width: none;
    }

    @include media-breakpoint-up(md) {
      // width: 37rem;

    }

    @include media-breakpoint-up(xl) {
      // width: 40rem;
    }
  }

  span {
    display: inline-block;
    span {
      display: inline;
    }
    // position: relative;
    // white-space: nowrap;
  }

}
