.WorkHeader {

  @include spacer("p", "t", 7);
  @include spacer("p", "b", 5);

  @media (min-width: 375px) {
    @include spacer("p", "b", 6);
  }

  @include media-breakpoint-up(sm) {
    @include spacer("p", "t", 8);
    @include spacer("p", "b", 7);
  }

  @include media-breakpoint-up(lg) {
    @include spacer("p", "t", 9);
  }

  &-aside {
    // @include font-smoothing("auto");
    @include media-breakpoint-down(xs) {
      display: none;
    }
    // @include spacer("m", "t", 3);
    @include media-breakpoint-up(sm) {
      // @include spacer("m", "t", 5);
    }
  }


  &-caption {
    margin-top: 0;
    @include spacer-stack(2);
  }

  &-title {
    margin: 0;
  }

  &-text {
    margin: 0;
  }

  &-caption,
  &-title,
  &-text {
    visibility: hidden;

    .is-visible & {
      visibility: visible;
    }
  }

}
