.SvgLibrary {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
}

.icon-stroke {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-width: 1.7px;
  @include media-breakpoint-down(xs) {
    stroke-width: 1.5px;
  }
}
