.PageHeader {
  overflow: hidden;
  text-align: center;

  padding-top: calc(var(--step-10) * 4);

  // @include spacer("p", "t", 7);

  // @include media-breakpoint-up(sm) {
  //   @include spacer("p", "t", 8);
  //   // @include spacer("p", "b", 7);
  // }

  // @include media-breakpoint-up(lg) {
  //   @include spacer("p", "t", 9);
  // }


  &-caption {
    margin: 0;
    font-weight: 600;
    @include spacer("p", "b", 3);
  }

  &-title {
    margin: 0;
    font-weight: 400;
    padding-bottom: calc(var(--step-10) * 3);

    > * {
      will-change: transform;
    }
  }

  &-text {
    margin: 0;
  }

  &-caption,
  &-title,
  &-text {
    visibility: hidden;

    .is-visible & {
      visibility: visible;
    }
  }
}
