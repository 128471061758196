.Gallery {
  position: relative;
}

.Gallery-slides {
  padding-bottom: 50%;
  position: relative;
  @include media-breakpoint-down(sm) {
    padding-bottom: 75%;
  }
}

.Gallery-slide,
.Gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Gallery-slide {
  transition: opacity 1s;
  opacity: 0;

  &.is-active {
    opacity: 1;
  }
}

.Gallery-image {
  transition: opacity 1.2s;
  opacity: 0;
  background-size: cover;
  background-position: center;

  &.lazyloaded {
    opacity: 1;
  }
}

.Gallery-nav {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  max-width: unset;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);

  html:not(.desktop) & {
    display: none;
  }
}

.Gallery-button {
  display: flex;
  padding: 10px 0;
  cursor: pointer;

  svg {
    transition: transform .3s, opacity .3s;
  }

  html.desktop &:hover {
    svg {
      opacity: .6;
      transform: translateX(5px);
    }
  }
}

.Gallery-button[data-button="prev"] {
  transform: rotate(180deg);
}

.Gallery-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 3px;
  background-color: rgba($color-basilico, 0.2);
}

.Gallery-track {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  background-color: currentcolor;
}
