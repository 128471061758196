.modal-body {
  text-align: center;
  > *:last-child {
    @include spacer("m", "b", 0);
  }
}

// .modal-header {
//   align-items: center;
// }

.modal-title {
  @include spacer("m", "t", 2);
}

.modal-footer > * {
  @include spacer("m", "b", 2);
}

.modal-header,
.modal-footer {
  justify-content: center;
}

.zoom {
  opacity: 0;
  transition: opacity .1s;
}

.modal.zoom {

  .modal-content {
    transform: scale(0.9);
    transition: transform .3s ease-out;
  }
  &.show {
    opacity: 1;
    .modal-content {
    transform: scale(1);
    }
  }

}
