.Media {

  .WorkBar + & {
    background-color: $color-background-dark;
  }

  $c : &;

  &-image,
  &-video {
    width: 100%;
    height: auto;
    margin: auto;
    display: block;
  }

  &-image,
  &-video {
    opacity: 0;
    transition: opacity .4s;
    .is-loaded & {
      opacity: 1;
    }
  }

  // &-video {
  //   opacity: 0;
  //   &.is-visible {
  //     opacity: 1;
  //   }
  // }

  &-item {
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: $color-background-placeholder;
      opacity: 1;
      transition: opacity .4s;
    }

    &.is-loaded:before {
      opacity: 0;
    }

    margin:0;
    & + & {
      @include spacer("m", "t", 3);
      @include media-breakpoint-up(sm) {
        @include spacer("m", "t", 6);
      }
    }
  }

  &-col {
    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
  }

  &--fullWidth {
    &.Section {
      padding:0;
    }

    .Container {
      padding: 0;
      max-width: 100%;
    }
    .row {
      margin: 0;
    }

    #{$c}-image {
      width: 100%;
    }
    #{$c}-item {
      padding-top: 0;
    }

    .Media-col {
      margin: 0;
      padding: 0;
      @include media-breakpoint-up(lg) {
        @include make-col(12);
        @include make-col-offset(0);
      }
    }
  }

}
