.InputRange {

  @include spacer-stack(4);

  &-labels {
    display: flex;
    justify-content: space-between;
    position: relative;
    // text-transform: uppercase;
    font-weight: 700;
    user-select: none;
    @include spacer("p", "b", 3);
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &-label {
    flex-grow: 1;
    flex-basis: 0;
    white-space: nowrap;
    text-align: center;
    position: relative;
    text-align: left;
    font-size: 14px;
    @include media-breakpoint-up(xl) {
      font-size: 16px;
    }
    &:not(:first-child):not(:last-child) {
      span {
        display: inline-block;
        transform: translateX(-50%);
      }
    }
    &:last-child {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &-text {
    cursor: pointer;
    transition: opacity .3s;
    .desktop &:hover {
      opacity: .5;
    }
  }

  &-select {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }


  &-slider {
    @include media-breakpoint-down(xs) {
      display: none;
    }
    height: 12px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: $border-inverted-grey;
      top: 5px;
      left:0;
    }
  }

  &-handle {
    position: relative;
    touch-action: pan-y;
    cursor: pointer;
    width: 0;
    height: 10px;
    top: 6px;
    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
    }
    &:after {
      width: 6px;
      height: 6px;
      left: -3px;
      top: -3px;
      border-radius: 50%;
      background-color: currentColor;
    }
    &:before {
      width: 18px;
      height: 18px;
      left: -9px;
      top: -9px;
      border-radius: 50%;
      border: 1px solid currentColor;
    }

  }

}
