.Customer {
  position: relative;
  // opacity: .5;
  background-color: $color-background-dark;

  img {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    opacity: 0;
    transition: opacity .3s;

    &.lazyloaded {
      opacity: 1;
    }

  }
}
