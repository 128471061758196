.row {
  &--no-gutter {
    margin-left: 0;
    margin-right: 0;

    > .col,
    > [class*="col-"],
    > [class*=" col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--double-gutter {

    @include media-breakpoint-up(sm) {
      margin-left: - $grid-gutter-width-base;
      margin-right: - $grid-gutter-width-base;

      > .col,
      > [class*="col-"],
      > [class*=" col-"] {
        padding-left: $grid-gutter-width-base;
        padding-right: $grid-gutter-width-base;
      }
    }

  }
}
