.Video {
  position: relative;
  height: 0;
  padding-bottom: calc(9 / 16 * 100%); // default to 16:9

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}
