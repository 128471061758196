.Section {

  $c : &;
  $b : ".Background--";

  padding: 3rem 0;
  @include media-breakpoint-up(sm) {
    padding: 10% 0;
  }
  @include media-breakpoint-up(xxl) {
    padding: 11rem 0;
  }

  &--halfPaddingTop {
    padding-top: 1.5rem;
    @include media-breakpoint-up(sm) {
      padding-top: 5%;
    }
    @include media-breakpoint-up(xxl) {
      padding-top: 5.5rem;
    }
  }

  &--halfPaddingBottom {
    padding-bottom: 1.5rem;
    @include media-breakpoint-up(sm) {
      padding-bottom: 5%;
    }
    @include media-breakpoint-up(xxl) {
      padding-bottom: 5.5rem;
    }
  }

  &--noPaddingBottom {
    padding-bottom: 0 !important;
  }

  &--noPaddingTop {
    padding-top: 0 !important;
  }

  &:not(#{$b}lighter):not(#{$b}light):not(#{$b}dark):not(#{$b}darker):not(.Media--fullWidth) {
    & + &,
    .PageHeader + & {
      padding-top: 0 !important;
    }
  }

  &#{$b}lighter {
    & + &,
    .PageHeader#{$b}lighter + & {
      padding-top: 0 !important;
    }
  }

  &#{$b}light {
    & + &,
    .PageHeader#{$b}light + & {
      padding-top: 0 !important;
    }
  }

  &#{$b}dark {
    & + &,
    .PageHeader#{$b}dark + & {
      padding-top: 0 !important;
    }
  }

  &#{$b}darker {
    & + &,
    .PageHeader#{$b}darker + & {
      padding-top: 0 !important;
    }
  }
}
