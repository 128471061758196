.pace {
  pointer-events: none;
  user-select: none;
  display: none;
  html.first-run & {
    display: block;
  }
  .page-transitioning--workFilter & {
    display: none !important;
  }
}

.pace-inactive {
  display: none !important;
}

.pace .pace-progress {
  background: #fff;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}
