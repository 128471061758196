#Mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  background-color: $color-background;
  pointer-events: none;
  will-change: opacity;
  opacity: 0;
  z-index: $zindex-fixed - 1;

  .page-transitioning--work & {
    z-index: $zindex-fixed + 1;
  }
  &.in {
    pointer-events: auto;
  }
}
