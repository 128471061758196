@mixin RevealStaggerEvery2() {
  &:nth-child(even) {
    transition-delay: .25s;
  }
}

@mixin RevealStaggerEvery3() {
  &:nth-child(3n+2) {
    transition-delay: .25s;
  }
  &:nth-child(3n+3) {
    transition-delay: .50s;
  }
}



.Reveal {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s, transform 1s;

  &--x {
    transform: translateX(30px) translateY(0);
  }

  &.is-shown {
    opacity: 1;
    transform: translate(0, 0);
  }

  .Media--fullWidth & {
    transform: translate(0, 0) !important;
  }

  .TextWAside & {
    @include media-breakpoint-up(sm) {
      @include RevealStaggerEvery2();
    }
  }

  .ActivityPanelList & {
    @include media-breakpoint-up(md) {
      @include RevealStaggerEvery2();
    }
  }

  .CustomerList & {
    @include media-breakpoint-only(sm) {
      @include RevealStaggerEvery2();
    }
    @include media-breakpoint-up(md) {
      @include RevealStaggerEvery3();
    }
  }

  .WorkBar + .Media & {
    transform: translate(0, 0) !important;
  }
}
