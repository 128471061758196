/* Core Utility to add margin and padding, used by the spacer mixins below */
@mixin core-spacer($abbrev: "p", $sides: "", $size: 1rem, $important: false) {
  @if ($sides == "x") {
    @include core-spacer($abbrev, "r", $size, $important);
    @include core-spacer($abbrev, "l", $size, $important);
  } @else if ($sides == "y") {
    @include core-spacer($abbrev, "t", $size, $important);
    @include core-spacer($abbrev, "b", $size, $important);
  } @else {
    $prop:      "padding";
    @if ($abbrev == "m") {
      $prop: "margin";
    }
    $prop-sides: "";
    @if ($sides == "t") {
      $prop-sides:  "-top";
    } @else if ($sides == "r") {
      $prop-sides:  "-right";
    } @else if ($sides == "b") {
      $prop-sides:  "-bottom";
    } @else if ($sides == "l") {
      $prop-sides:  "-left";
    }
    $_important: "";
    @if ($important) {
      $_important: " !important";
    }
    #{$prop}#{$prop-sides}: $size#{$_important};
  }
}

/* Responsive spacer based on grid-gutter-width values */
@mixin grid-spacer($abbrev: "p", $sides: "", $factor: 1, $grid-gutter-widths: $grid-gutter-widths, $important: false) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $mediaFactor : $factor;
      @if type-of($factor) == 'map' {
        $mediaFactor : map-get($factor, $breakpoint);
      }
      $size : map-get($grid-gutter-widths, $breakpoint) * $mediaFactor;
      @include core-spacer($abbrev, $sides, $size, $important);
    }
  }
}

/* Spacer mixins based on font-size-base */
@mixin spacer($abbrev: "p", $sides: "", $size: 3, $important: false, $factor: 1) {
  $unit : $font-size-base;
  $units: (
    0: 0,
    1: $unit * .25,  //  .25rem    4px
    2: $unit * .5,   //   .5rem    8px
    3: $unit,        //    1rem   16px
    4: $unit * 1.5,  //  1.5rem   24px
    5: $unit * 2,    //    2rem   32px
    6: $unit * 4,    //    4rem   64px
    7: $unit * 8,    //    8rem  128px
    8: $unit * 12,   //    12rem  192px
    9: $unit * 16,   //    16rem  256px
    10: $unit * 18,   //    16rem  256px
  );
  $width : map-get($units, $size) * $factor;
  @include core-spacer($abbrev, $sides, $width, $important);
}

/* Spacer shortcut for margin bottom */
@mixin spacer-stack($size: 3, $important: false) {
  @include spacer("m", "b", $size, $important);
}

/* Spacer shortcut for padding */
@mixin spacer-inset($size: 3, $important: false) {
  @include spacer("p", "", $size, $important);
}

/* Spacer shortcut for margin-right */
@mixin spacer-inline($size: 3, $important: false) {
  @include spacer("m", "r", $size, $important);
}
