@charset "UTF-8";
/*
  grid
*/
/*
  design settings
*/
/*
  spacers
*/
/*
  modular scale
*/
/* Font Face */
/* font smoothing */
/* Core Utility to add margin and padding, used by the spacer mixins below */
/* Responsive spacer based on grid-gutter-width values */
/* Spacer mixins based on font-size-base */
/* Spacer shortcut for margin bottom */
/* Spacer shortcut for padding */
/* Spacer shortcut for margin-right */
/* bootstrap modified files */
/* bootstrap dev files */
/*

  Mixin from Bootstrap v4-dev
  https://github.com/patrickhlauke/bootstrap/commit/87dc9431c6334802adb0a2066976f1724ef18de1

*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Neue Montreal", Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.9;
  color: #212121;
  text-align: left;
  background-color: #ededed;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d0d0d;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: black;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #f00;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Neue Montreal", Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.1;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small,
.TextWAside-aside,
.MandatoryNote {
  font-size: 87.5%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled, .ActivityPanel-list {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 87.5%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

@media (min-width: 768px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1300px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1600px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--gutter) * -0.5);
  margin-left: calc(var(--gutter) * -0.5);
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: calc(var(--gutter) * 0.5);
  padding-left: calc(var(--gutter) * 0.5);
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.3333333333%;
}

.pull-2 {
  right: 16.6666666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.3333333333%;
}

.pull-5 {
  right: 41.6666666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.3333333333%;
}

.pull-8 {
  right: 66.6666666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.3333333333%;
}

.pull-11 {
  right: 91.6666666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.3333333333%;
}

.push-2 {
  left: 16.6666666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.3333333333%;
}

.push-5 {
  left: 41.6666666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.3333333333%;
}

.push-8 {
  left: 66.6666666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.3333333333%;
}

.push-11 {
  left: 91.6666666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-sm-0 {
    right: auto;
  }
  .pull-sm-1 {
    right: 8.3333333333%;
  }
  .pull-sm-2 {
    right: 16.6666666667%;
  }
  .pull-sm-3 {
    right: 25%;
  }
  .pull-sm-4 {
    right: 33.3333333333%;
  }
  .pull-sm-5 {
    right: 41.6666666667%;
  }
  .pull-sm-6 {
    right: 50%;
  }
  .pull-sm-7 {
    right: 58.3333333333%;
  }
  .pull-sm-8 {
    right: 66.6666666667%;
  }
  .pull-sm-9 {
    right: 75%;
  }
  .pull-sm-10 {
    right: 83.3333333333%;
  }
  .pull-sm-11 {
    right: 91.6666666667%;
  }
  .pull-sm-12 {
    right: 100%;
  }
  .push-sm-0 {
    left: auto;
  }
  .push-sm-1 {
    left: 8.3333333333%;
  }
  .push-sm-2 {
    left: 16.6666666667%;
  }
  .push-sm-3 {
    left: 25%;
  }
  .push-sm-4 {
    left: 33.3333333333%;
  }
  .push-sm-5 {
    left: 41.6666666667%;
  }
  .push-sm-6 {
    left: 50%;
  }
  .push-sm-7 {
    left: 58.3333333333%;
  }
  .push-sm-8 {
    left: 66.6666666667%;
  }
  .push-sm-9 {
    left: 75%;
  }
  .push-sm-10 {
    left: 83.3333333333%;
  }
  .push-sm-11 {
    left: 91.6666666667%;
  }
  .push-sm-12 {
    left: 100%;
  }
  .offset-sm-0 {
    margin-left: 0%;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-md-0 {
    right: auto;
  }
  .pull-md-1 {
    right: 8.3333333333%;
  }
  .pull-md-2 {
    right: 16.6666666667%;
  }
  .pull-md-3 {
    right: 25%;
  }
  .pull-md-4 {
    right: 33.3333333333%;
  }
  .pull-md-5 {
    right: 41.6666666667%;
  }
  .pull-md-6 {
    right: 50%;
  }
  .pull-md-7 {
    right: 58.3333333333%;
  }
  .pull-md-8 {
    right: 66.6666666667%;
  }
  .pull-md-9 {
    right: 75%;
  }
  .pull-md-10 {
    right: 83.3333333333%;
  }
  .pull-md-11 {
    right: 91.6666666667%;
  }
  .pull-md-12 {
    right: 100%;
  }
  .push-md-0 {
    left: auto;
  }
  .push-md-1 {
    left: 8.3333333333%;
  }
  .push-md-2 {
    left: 16.6666666667%;
  }
  .push-md-3 {
    left: 25%;
  }
  .push-md-4 {
    left: 33.3333333333%;
  }
  .push-md-5 {
    left: 41.6666666667%;
  }
  .push-md-6 {
    left: 50%;
  }
  .push-md-7 {
    left: 58.3333333333%;
  }
  .push-md-8 {
    left: 66.6666666667%;
  }
  .push-md-9 {
    left: 75%;
  }
  .push-md-10 {
    left: 83.3333333333%;
  }
  .push-md-11 {
    left: 91.6666666667%;
  }
  .push-md-12 {
    left: 100%;
  }
  .offset-md-0 {
    margin-left: 0%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1300px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-lg-0 {
    right: auto;
  }
  .pull-lg-1 {
    right: 8.3333333333%;
  }
  .pull-lg-2 {
    right: 16.6666666667%;
  }
  .pull-lg-3 {
    right: 25%;
  }
  .pull-lg-4 {
    right: 33.3333333333%;
  }
  .pull-lg-5 {
    right: 41.6666666667%;
  }
  .pull-lg-6 {
    right: 50%;
  }
  .pull-lg-7 {
    right: 58.3333333333%;
  }
  .pull-lg-8 {
    right: 66.6666666667%;
  }
  .pull-lg-9 {
    right: 75%;
  }
  .pull-lg-10 {
    right: 83.3333333333%;
  }
  .pull-lg-11 {
    right: 91.6666666667%;
  }
  .pull-lg-12 {
    right: 100%;
  }
  .push-lg-0 {
    left: auto;
  }
  .push-lg-1 {
    left: 8.3333333333%;
  }
  .push-lg-2 {
    left: 16.6666666667%;
  }
  .push-lg-3 {
    left: 25%;
  }
  .push-lg-4 {
    left: 33.3333333333%;
  }
  .push-lg-5 {
    left: 41.6666666667%;
  }
  .push-lg-6 {
    left: 50%;
  }
  .push-lg-7 {
    left: 58.3333333333%;
  }
  .push-lg-8 {
    left: 66.6666666667%;
  }
  .push-lg-9 {
    left: 75%;
  }
  .push-lg-10 {
    left: 83.3333333333%;
  }
  .push-lg-11 {
    left: 91.6666666667%;
  }
  .push-lg-12 {
    left: 100%;
  }
  .offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-xl-0 {
    right: auto;
  }
  .pull-xl-1 {
    right: 8.3333333333%;
  }
  .pull-xl-2 {
    right: 16.6666666667%;
  }
  .pull-xl-3 {
    right: 25%;
  }
  .pull-xl-4 {
    right: 33.3333333333%;
  }
  .pull-xl-5 {
    right: 41.6666666667%;
  }
  .pull-xl-6 {
    right: 50%;
  }
  .pull-xl-7 {
    right: 58.3333333333%;
  }
  .pull-xl-8 {
    right: 66.6666666667%;
  }
  .pull-xl-9 {
    right: 75%;
  }
  .pull-xl-10 {
    right: 83.3333333333%;
  }
  .pull-xl-11 {
    right: 91.6666666667%;
  }
  .pull-xl-12 {
    right: 100%;
  }
  .push-xl-0 {
    left: auto;
  }
  .push-xl-1 {
    left: 8.3333333333%;
  }
  .push-xl-2 {
    left: 16.6666666667%;
  }
  .push-xl-3 {
    left: 25%;
  }
  .push-xl-4 {
    left: 33.3333333333%;
  }
  .push-xl-5 {
    left: 41.6666666667%;
  }
  .push-xl-6 {
    left: 50%;
  }
  .push-xl-7 {
    left: 58.3333333333%;
  }
  .push-xl-8 {
    left: 66.6666666667%;
  }
  .push-xl-9 {
    left: 75%;
  }
  .push-xl-10 {
    left: 83.3333333333%;
  }
  .push-xl-11 {
    left: 91.6666666667%;
  }
  .push-xl-12 {
    left: 100%;
  }
  .offset-xl-0 {
    margin-left: 0%;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 2400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-xxl-0 {
    right: auto;
  }
  .pull-xxl-1 {
    right: 8.3333333333%;
  }
  .pull-xxl-2 {
    right: 16.6666666667%;
  }
  .pull-xxl-3 {
    right: 25%;
  }
  .pull-xxl-4 {
    right: 33.3333333333%;
  }
  .pull-xxl-5 {
    right: 41.6666666667%;
  }
  .pull-xxl-6 {
    right: 50%;
  }
  .pull-xxl-7 {
    right: 58.3333333333%;
  }
  .pull-xxl-8 {
    right: 66.6666666667%;
  }
  .pull-xxl-9 {
    right: 75%;
  }
  .pull-xxl-10 {
    right: 83.3333333333%;
  }
  .pull-xxl-11 {
    right: 91.6666666667%;
  }
  .pull-xxl-12 {
    right: 100%;
  }
  .push-xxl-0 {
    left: auto;
  }
  .push-xxl-1 {
    left: 8.3333333333%;
  }
  .push-xxl-2 {
    left: 16.6666666667%;
  }
  .push-xxl-3 {
    left: 25%;
  }
  .push-xxl-4 {
    left: 33.3333333333%;
  }
  .push-xxl-5 {
    left: 41.6666666667%;
  }
  .push-xxl-6 {
    left: 50%;
  }
  .push-xxl-7 {
    left: 58.3333333333%;
  }
  .push-xxl-8 {
    left: 66.6666666667%;
  }
  .push-xxl-9 {
    left: 75%;
  }
  .push-xxl-10 {
    left: 83.3333333333%;
  }
  .push-xxl-11 {
    left: 91.6666666667%;
  }
  .push-xxl-12 {
    left: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0%;
  }
  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.9rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.45rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #212121;
  border-color: #86d4b2;
  background-color: #86d4b2;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #212121, 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.45rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #0d0d0d;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.45rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23212121' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23212121' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23212121'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.45rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #0d0d0d;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.9em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.9;
  color: #fff;
  vertical-align: middle;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23666666' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #474747;
  border-radius: 0;
  appearance: none;
}
.custom-select:focus {
  border-color: #fff;
  outline: 0;
  box-shadow: none;
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #fff;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.9em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.9em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.9em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 300;
  line-height: 1.9;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.9em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.9;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ededed, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ededed, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ededed, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.1;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1300px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1300px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 2400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1300px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 2400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1300px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 2400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 8rem !important;
}

.mt-7,
.my-7 {
  margin-top: 8rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 8rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 8rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 8rem !important;
}

.m-8 {
  margin: 12rem !important;
}

.mt-8,
.my-8 {
  margin-top: 12rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 12rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 12rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 12rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 8rem !important;
}

.pt-7,
.py-7 {
  padding-top: 8rem !important;
}

.pr-7,
.px-7 {
  padding-right: 8rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 8rem !important;
}

.pl-7,
.px-7 {
  padding-left: 8rem !important;
}

.p-8 {
  padding: 12rem !important;
}

.pt-8,
.py-8 {
  padding-top: 12rem !important;
}

.pr-8,
.px-8 {
  padding-right: 12rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 12rem !important;
}

.pl-8,
.px-8 {
  padding-left: 12rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important;
}

.m-n7 {
  margin: -8rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -8rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -8rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -8rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -8rem !important;
}

.m-n8 {
  margin: -12rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -12rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -12rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -12rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -12rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-7 {
    margin: 8rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 8rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 8rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 8rem !important;
  }
  .m-sm-8 {
    margin: 12rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 12rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 12rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 12rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-7 {
    padding: 8rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 8rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 8rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 8rem !important;
  }
  .p-sm-8 {
    padding: 12rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 12rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 12rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 12rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 12rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -4rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important;
  }
  .m-sm-n7 {
    margin: -8rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -8rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -8rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -8rem !important;
  }
  .m-sm-n8 {
    margin: -12rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -12rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -12rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -12rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-7 {
    margin: 8rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 8rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 8rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 8rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 8rem !important;
  }
  .m-md-8 {
    margin: 12rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 12rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 12rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 12rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 12rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-7 {
    padding: 8rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 8rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 8rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 8rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 8rem !important;
  }
  .p-md-8 {
    padding: 12rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 12rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 12rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 12rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 12rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -4rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important;
  }
  .m-md-n7 {
    margin: -8rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -8rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -8rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -8rem !important;
  }
  .m-md-n8 {
    margin: -12rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -12rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -12rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -12rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1300px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-7 {
    margin: 8rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 8rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 8rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 8rem !important;
  }
  .m-lg-8 {
    margin: 12rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 12rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 12rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 12rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-7 {
    padding: 8rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 8rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 8rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 8rem !important;
  }
  .p-lg-8 {
    padding: 12rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 12rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 12rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 12rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 12rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -4rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important;
  }
  .m-lg-n7 {
    margin: -8rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -8rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -8rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -8rem !important;
  }
  .m-lg-n8 {
    margin: -12rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -12rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -12rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -12rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-7 {
    margin: 8rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 8rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 8rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 8rem !important;
  }
  .m-xl-8 {
    margin: 12rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 12rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 12rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 12rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-7 {
    padding: 8rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 8rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 8rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 8rem !important;
  }
  .p-xl-8 {
    padding: 12rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 12rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 12rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 12rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 12rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -4rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important;
  }
  .m-xl-n7 {
    margin: -8rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -8rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -8rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -8rem !important;
  }
  .m-xl-n8 {
    margin: -12rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -12rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -12rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -12rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 2400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 2rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 2rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 2rem !important;
  }
  .m-xxl-6 {
    margin: 4rem !important;
  }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 4rem !important;
  }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 4rem !important;
  }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 4rem !important;
  }
  .m-xxl-7 {
    margin: 8rem !important;
  }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 8rem !important;
  }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 8rem !important;
  }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 8rem !important;
  }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 8rem !important;
  }
  .m-xxl-8 {
    margin: 12rem !important;
  }
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 12rem !important;
  }
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 12rem !important;
  }
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 12rem !important;
  }
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 12rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 2rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 2rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 2rem !important;
  }
  .p-xxl-6 {
    padding: 4rem !important;
  }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 4rem !important;
  }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 4rem !important;
  }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 4rem !important;
  }
  .p-xxl-7 {
    padding: 8rem !important;
  }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 8rem !important;
  }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 8rem !important;
  }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 8rem !important;
  }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 8rem !important;
  }
  .p-xxl-8 {
    padding: 12rem !important;
  }
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 12rem !important;
  }
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 12rem !important;
  }
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 12rem !important;
  }
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 12rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -2rem !important;
  }
  .m-xxl-n6 {
    margin: -4rem !important;
  }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -4rem !important;
  }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -4rem !important;
  }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -4rem !important;
  }
  .m-xxl-n7 {
    margin: -8rem !important;
  }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -8rem !important;
  }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -8rem !important;
  }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -8rem !important;
  }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -8rem !important;
  }
  .m-xxl-n8 {
    margin: -12rem !important;
  }
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -12rem !important;
  }
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -12rem !important;
  }
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -12rem !important;
  }
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -12rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1300px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 2400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212121 !important;
}

.text-muted {
  color: #f00 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.rangeslider,
.rangeslider__fill {
  display: block;
  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.rangeslider {
  background: #e6e6e6;
  position: relative;
}

.rangeslider--horizontal {
  height: 20px;
  width: 100%;
}

.rangeslider--vertical {
  width: 20px;
  min-height: 150px;
  max-height: 100%;
  height: 100%;
}

.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

.rangeslider__fill {
  background: #00ff00;
  position: absolute;
}

.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%;
}

.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%;
}

.rangeslider__handle {
  background: white;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: absolute;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.1)));
  background-image: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.rangeslider__handle:after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.13)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
  background-image: linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.rangeslider__handle:active, .rangeslider--active .rangeslider__handle {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
}

.rangeslider--horizontal .rangeslider__handle {
  top: -10px;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}

.rangeslider--vertical .rangeslider__handle {
  left: -10px;
  touch-action: pan-x;
  -ms-touch-action: pan-x;
}

input[type=range]:focus + .rangeslider .rangeslider__handle {
  -moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
}

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
:root {
  --fluid-screen: 100vw;
  --fluid-bp: calc((var(--fluid-screen) - 20rem) / 1600);
  --step--2: calc(0.87375rem + -0.6 * var(--fluid-bp));
  --step--1: calc(0.934375rem + 0.57 * var(--fluid-bp));
  --step-0: calc(1rem + 2 * var(--fluid-bp));
  --step-1: calc(1.07rem + 3.76 * var(--fluid-bp));
  --step-2: calc(1.145rem + 5.9 * var(--fluid-bp));
  --step-3: calc(1.225rem + 8.5 * var(--fluid-bp));
  --step-4: calc(1.310625rem + 11.62 * var(--fluid-bp));
  --step-5: calc(1.4025rem + 15.37 * var(--fluid-bp));
  --step-6: calc(1.500625rem + 19.85 * var(--fluid-bp));
  --step-7: calc(1.605625rem + 25.18 * var(--fluid-bp));
  --step-8: calc(1.718125rem + 31.52 * var(--fluid-bp));
  --step-9: calc(1.83875rem + 39.03 * var(--fluid-bp));
  --step-10: calc(1.966875rem + 47.94 * var(--fluid-bp));
  --space-3xs: calc(0.25rem + 0.5 * var(--fluid-bp));
  --space-2xs: calc(0.5rem + 1 * var(--fluid-bp));
  --space-xs: calc(0.75rem + 1.5 * var(--fluid-bp));
  --space-s: calc(1rem + 2 * var(--fluid-bp));
  --space-m: calc(2rem + 4 * var(--fluid-bp));
  --space-l: calc(3rem + 6 * var(--fluid-bp));
  --space-xl: calc(4rem + 8 * var(--fluid-bp));
  --space-2xl: calc(6rem + 12 * var(--fluid-bp));
  --space-3xl: calc(10rem + 20 * var(--fluid-bp));
  --space-4xl: calc(12rem + 24 * var(--fluid-bp));
  --space-3xs-2xs: calc(0.25rem + 5 * var(--fluid-bp));
  --space-2xs-xs: calc(0.5rem + 5.5 * var(--fluid-bp));
  --space-xs-s: calc(0.75rem + 6 * var(--fluid-bp));
  --space-s-m: calc(1rem + 20 * var(--fluid-bp));
  --space-m-l: calc(2rem + 22 * var(--fluid-bp));
  --space-l-xl: calc(3rem + 24 * var(--fluid-bp));
  --space-xl-2xl: calc(4rem + 44 * var(--fluid-bp));
  --space-2xl-3xl: calc(6rem + 84 * var(--fluid-bp));
  --space-3xl-4xl: calc(10rem + 56 * var(--fluid-bp));
  --space-s-xl: calc(1rem + 56 * var(--fluid-bp));
  --space-s-2xl: calc(1rem + 92 * var(--fluid-bp));
  --container-padding: var(--space-s);
  --gutter: 16px;
}
@media screen and (min-width: 1920px) {
  :root {
    --fluid-screen: 1920px;
  }
}
@media (min-width: 768px) {
  :root {
    --container-padding: var(--space-s-xl);
  }
}
@media (min-width: 768px) {
  :root {
    --gutter: 32px;
  }
}
@media (min-width: 992px) {
  :root {
    --gutter: 32px;
  }
}
@media (min-width: 1300px) {
  :root {
    --gutter: 32px;
  }
}
@media (min-width: 1600px) {
  :root {
    --gutter: 32px;
  }
}
@media (min-width: 2400px) {
  :root {
    --gutter: 32px;
  }
}

* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
  font-size: var(--step-0);
  overflow-y: scroll;
  overscroll-behavior-y: none;
}

body {
  overscroll-behavior-y: none;
}
body.inverted-nav {
  background-color: #000;
}

#barba-wrapper {
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}
html.ready #barba-wrapper {
  opacity: 1;
}

.desktop .barba-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.desktop .barba-container .SiteFooter {
  margin-top: auto;
}

svg {
  fill: currentColor;
  vertical-align: middle;
}

input,
button,
textarea {
  outline: none !important;
  appearance: none;
  border-radius: 0;
}

label {
  user-select: none;
  display: block;
}
label.is-required:not(.custom-control-label):after {
  content: " *";
  font-weight: 300;
}

label, input, textarea {
  font-size: 21.9320814874px;
  letter-spacing: -0.03em;
  margin: 0;
}
@media (min-width: 768px) {
  label, input, textarea {
    font-size: 28.7562656642px;
  }
}
@media (min-width: 992px) {
  label, input, textarea {
    font-size: 32.6308869238px;
  }
}
@media (min-width: 1300px) {
  label, input, textarea {
    font-size: 32.6308869238px;
  }
}
@media (min-width: 1600px) {
  label, input, textarea {
    font-size: 37.3426846276px;
  }
}
@media (min-width: 2400px) {
  label, input, textarea {
    font-size: 39.8446998892px;
  }
}

label {
  font-weight: 300;
}

input, textarea {
  background-color: transparent;
  font-weight: 300;
  flex-grow: 1;
  width: 100%;
  border: none;
  padding: 0;
  color: #666666;
  transition: border-color 0.3s;
  outline: none;
}
input:focus, textarea:focus {
  border-color: #fff !important;
}
input:not(:placeholder-shown), textarea:not(:placeholder-shown) {
  border-color: #666666;
}
input:placeholder-shown, textarea:placeholder-shown {
  border-color: #333;
}

textarea {
  line-height: 1.2;
  resize: none;
}

@media (min-width: 768px) {
  fieldset {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1600px) {
  fieldset {
    margin-bottom: 4rem;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #333;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #333;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #333;
}

:-moz-placeholder { /* Firefox 18- */
  color: #333;
  opacity: 1;
}

::selection {
  background: #aaa;
}

::-moz-selection {
  background: #aaa;
}

body.cke_editable {
  margin: 1rem;
}
body.cke_editable hr {
  visibility: visible !important;
}

@font-face {
  font-family: "Neue Montreal";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/PPNeueMontreal-Regular.woff2") format("woff2"), url("../fonts/PPNeueMontreal-Regular.woff") format("woff");
}
@font-face {
  font-family: "Neue Montreal";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/PPNeueMontreal-Medium.woff2") format("woff2"), url("../fonts/PPNeueMontreal-Medium.woff") format("woff");
}
@font-face {
  font-family: "Neue Montreal";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/PPNeueMontreal-SemiBold.woff2") format("woff2"), url("../fonts/PPNeueMontreal-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Neue Montreal";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/PPNeueMontreal-SemiBold.woff2") format("woff2"), url("../fonts/PPNeueMontreal-SemiBold.woff") format("woff");
}
.o-h {
  overflow: hidden !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0) translateZ(0);
  }
  100% {
    transform: translateX(-100%) translateZ(0);
  }
}
@keyframes marquee-right {
  0% {
    transform: translateX(-100%) translateZ(0);
  }
  100% {
    transform: translateX(0) translateZ(0);
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 span, h2 span, h3 span, h4 span, h5 span, h6 span, .h1 span, .h2 span, .h3 span, .h4 span, .h5 span, .h6 span {
  display: inline-block;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.text-muted {
  color: #a6a6a6 !important;
}
.Background--dark .text-muted, .Background--darker .text-muted, .Background--black .text-muted {
  color: #666666 !important;
}

small,
.small,
.TextWAside-aside,
.MandatoryNote {
  line-height: 1.5;
  font-size: 87.5%;
  font-weight: 500;
}
@media (min-width: 1600px) {
  small,
  .small,
  .TextWAside-aside,
  .MandatoryNote {
    font-size: 15px;
  }
}

strong,
.strong,
b {
  font-weight: 600;
}

pre {
  padding: 0.2rem 0.5rem;
  background-color: #eee;
  color: #666;
}

.row--no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.row--no-gutter > .col,
.row--no-gutter > [class*=col-],
.row--no-gutter > [class*=" col-"] {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .row--double-gutter {
    margin-left: -32px;
    margin-right: -32px;
  }
  .row--double-gutter > .col,
  .row--double-gutter > [class*=col-],
  .row--double-gutter > [class*=" col-"] {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.custom-control-label:before, .custom-control-label:after {
  top: 0.15rem;
}
.custom-control-label:before {
  border: 1px solid #474747;
}

.custom-control-input {
  width: 1rem;
  height: 1rem;
  left: 0;
  top: 0.15rem;
}

label.custom-control-label {
  font-size: 87.5%;
  font-weight: normal;
}
@media (min-width: 1600px) {
  label.custom-control-label {
    font-size: 15px;
  }
}
label.custom-control-label a {
  text-decoration: underline;
}

.modal-body {
  text-align: center;
}
.modal-body > *:last-child {
  margin-bottom: 0;
}

.modal-title {
  margin-top: 0.5rem;
}

.modal-footer > * {
  margin-bottom: 0.5rem;
}

.modal-header,
.modal-footer {
  justify-content: center;
}

.zoom {
  opacity: 0;
  transition: opacity 0.1s;
}

.modal.zoom .modal-content {
  transform: scale(0.9);
  transition: transform 0.3s ease-out;
}
.modal.zoom.show {
  opacity: 1;
}
.modal.zoom.show .modal-content {
  transform: scale(1);
}

/** Components **/
.ActivityPanel {
  position: relative;
}
.ActivityPanel-body {
  position: relative;
  background-color: #ededed;
  z-index: 2;
}
.Background--dark .ActivityPanel-body {
  background-color: #0d0d0d;
}
.Background--darker .ActivityPanel-body {
  background-color: #000;
}
.ActivityPanel-title {
  margin-top: 0 !important;
}
.ActivityPanel-text {
  margin-top: 0 !important;
  color: #a6a6a6;
}
.Background--dark .ActivityPanel-text, .Background--darker .ActivityPanel-text {
  color: #666666;
}
.ActivityPanel-list {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 1;
  margin-bottom: 0 !important;
}
.ActivityPanel-list li {
  margin-bottom: 0.5em;
}
.ActivityPanel-list li:last-child {
  margin-bottom: 0;
}
@media (min-width: 1600px) {
  .ActivityPanel-list {
    font-size: 16px;
  }
}

.ActivityPanelList {
  overflow: hidden;
  padding-top: 2px;
}
@media (min-width: 992px) {
  .ActivityPanelList {
    align-items: stretch;
    row-gap: 128px;
  }
  .ActivityPanelList > *[class^=col-] {
    display: flex;
  }
}
.ActivityPanelList .ActivityPanel {
  margin-bottom: 48px;
}
@media (min-width: 992px) {
  .ActivityPanelList .ActivityPanel {
    margin-bottom: 0;
    display: flex;
  }
}

.Awards-text {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .Awards-text {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 33.3333333333%;
  }
}
@media (min-width: 992px) {
  .Awards-text {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-left: 25%;
  }
}
@media (min-width: 1300px) {
  .Awards-text {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.Awards-title.Heading, .Prose blockquote.Awards-title, body.cke_editable blockquote.Awards-title, h1.Awards-title, h2.Awards-title, h3.Awards-title, h4.Awards-title, h5.Awards-title, h6.Awards-title, .Awards-title.h1, .Awards-title.h2, .Awards-title.h3, .Awards-title.h4, .Awards-title.h5, .Awards-title.h6, .Awards-title.ActivityPanel-title, .Awards-title.ActivityPanel-text, .Awards-title.ActivityPanel-list {
  margin-bottom: 2rem;
}
.Awards-item {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .Awards-item-text {
    font-size: 14px;
  }
}
.Awards-item-text--strong {
  font-weight: bold;
}
.Awards-item-text + .Awards-item-text:before {
  content: " - ";
}
.Awards-item--has-logo {
  margin-bottom: 1rem;
}
.Awards-logo {
  width: 150px;
  display: block;
}
@media (min-width: 768px) {
  .Awards-logo {
    display: inline-block;
    width: 200px;
  }
}
.Awards-link {
  text-decoration: none !important;
}
@media (min-width: 768px) {
  .Awards-link {
    display: inline-block;
    line-height: 1.7;
    position: relative;
    display: inline-block;
    transition: opacity 0.3s;
    text-decoration: none !important;
  }
  .Awards-link:before {
    --scale-x: 0;
    content: "";
    position: absolute;
    bottom: 9%;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    transform-origin: 0 0;
    transform: scaleX(var(--scale-x));
    opacity: 0.8;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
  }
  .desktop .Awards-link:hover {
    opacity: 0.3;
  }
  .desktop .Awards-link:hover:before {
    transform-origin: 100% 0;
    --scale-x: 1;
  }
  .Awards-link.is-active:before {
    opacity: 1;
    transform-origin: 100% 0;
    --scale-x: 1;
  }
}

.Background {
  background: #ededed;
}
.Background--light {
  background: #ededed;
}
.Background--lighter {
  background: #f7f7f7;
}
.Background--dark {
  background: #0d0d0d;
}
.Background--darker {
  background: #000;
}
.Background--black {
  background: #000;
}
.Background--dark, .Background--darker, .Background--black {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}
.Background--dark a, .Background--darker a, .Background--black a {
  color: #fff;
}
.Background--dark .text-muted, .Background--darker .text-muted, .Background--black .text-muted {
  color: #666666;
}
.Background.has-overflowHidden {
  overflow: hidden;
}

.Button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  text-decoration: none !important;
  outline: none;
  border: none;
  color: currentColor;
  background: none;
  appearance: none;
  padding: 0;
  line-height: 1;
  font-size: 87.5%;
  transition: border-color 0.3s, background 0.3s, color 0.3s;
  border-radius: 2em;
  padding: 1.2em 2em;
  min-width: 200px;
  background-color: #000;
  border: 2px solid rgba(255, 255, 255, 0.3);
}
.Button + .Button {
  margin-left: 32px;
}
.Button:hover {
  border: 2px solid rgba(255, 255, 255, 0.5);
}
.modal .Button {
  background: transparent;
  border-color: currentColor;
}
.Button.is-active {
  border-color: #ededed;
  background: #ededed;
  color: #000;
}
@media (max-width: 767.98px) {
  .Button--blockOnMobile {
    display: block;
    width: 100%;
  }
  .Button--blockOnMobile .Button-text {
    display: block;
  }
}

.Button--language {
  text-transform: none;
  border-color: #3e3e3e;
  opacity: 1 !important;
}
.Button--language:before {
  top: 5px;
  left: -5px;
  border-color: inherit;
}
.Button--language .Button-text {
  padding: 0.4em 1.3em 0.3em;
  min-width: 0;
}
.Button--language .Button-ballon {
  display: block;
  position: absolute;
  bottom: -3px;
  left: 14px;
  transform: translateY(100%);
  width: 14px;
  height: 11px;
}
.Button--language svg {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform: translateZ(0);
}
.Button--language polygon {
  transition: fill 0.3s;
  fill: #3e3e3e;
}
.Button--language polygon:last-child {
  fill: #000;
}
.Button--language:hover svg, .Button--language:hover:before {
  transform: translate(-2px, 2px) translateZ(0);
}
.Button--language:hover polygon {
  fill: #86d4b2 !important;
}

.Container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: 1920px;
}

.Customer {
  position: relative;
  background-color: #0d0d0d;
}
.Customer img {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0;
  transition: opacity 0.3s;
}
.Customer img.lazyloaded {
  opacity: 1;
}

.CustomerList .Customer {
  margin-bottom: calc(32px / 2);
}
@media (min-width: 768px) {
  .CustomerList .Customer {
    margin-bottom: calc(32px * 2);
  }
}

.Gallery {
  position: relative;
}

.Gallery-slides {
  padding-bottom: 50%;
  position: relative;
}
@media (max-width: 991.98px) {
  .Gallery-slides {
    padding-bottom: 75%;
  }
}

.Gallery-slide,
.Gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Gallery-slide {
  transition: opacity 1s;
  opacity: 0;
}
.Gallery-slide.is-active {
  opacity: 1;
}

.Gallery-image {
  transition: opacity 1.2s;
  opacity: 0;
  background-size: cover;
  background-position: center;
}
.Gallery-image.lazyloaded {
  opacity: 1;
}

.Gallery-nav {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  max-width: unset;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}
html:not(.desktop) .Gallery-nav {
  display: none;
}

.Gallery-button {
  display: flex;
  padding: 10px 0;
  cursor: pointer;
}
.Gallery-button svg {
  transition: transform 0.3s, opacity 0.3s;
}
html.desktop .Gallery-button:hover svg {
  opacity: 0.6;
  transform: translateX(5px);
}

.Gallery-button[data-button=prev] {
  transform: rotate(180deg);
}

.Gallery-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 3px;
  background-color: rgba(134, 212, 178, 0.2);
}

.Gallery-track {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  background-color: currentcolor;
}

.Heading, .Prose blockquote, body.cke_editable blockquote, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .ActivityPanel-title, .ActivityPanel-text, .ActivityPanel-list {
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: -0.02em;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.Heading--light {
  font-weight: 400;
}

.Heading--serif, .Prose blockquote, body.cke_editable blockquote, .ActivityPanel-text {
  font-family: "Neue Montreal", Helvetica, sans-serif;
  font-weight: 100;
  line-height: 1.2;
}

.Heading--huge {
  font-size: var(--step-8);
}

.Heading--main, h1, .h1 {
  font-size: var(--step-7);
}

.Heading--secondary, h2, .h2 {
  font-size: var(--step-5);
}

.Heading--medium, .Prose blockquote, body.cke_editable blockquote, h3, .h3 {
  font-size: var(--step-4);
}

.Heading--small, h4, .h4 {
  font-size: var(--step-3);
}

.Heading--bump, h5, .h5, .ActivityPanel-text {
  font-size: var(--step-2);
}

.Heading--body, h6, .h6 {
  font-size: var(--step-1);
  letter-spacing: 0;
}

.Heading--context, .ActivityPanel-title {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 1.7;
}
@media (min-width: 1600px) {
  .Heading--context, .ActivityPanel-title {
    font-size: 15px;
  }
}

.HeroCta {
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  line-height: 1;
}
.HeroCta + .HeroCta {
  padding-top: 0 !important;
  margin-top: -1rem;
}
@media (min-width: 768px) {
  .HeroCta + .HeroCta {
    margin-top: -1.5rem;
  }
}
@media (min-width: 1300px) {
  .HeroCta + .HeroCta {
    margin-top: -2rem;
  }
}
html:not(.first-run) .HeroCta {
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
}
@media (min-width: 768px) {
  .HeroCta {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 1300px) {
  .HeroCta {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.HeroCta-link {
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
  text-align: center;
}
.HeroCta-text {
  font-size: 36px;
  font-weight: 500;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  visibility: hidden;
}
@media (min-width: 768px) {
  .HeroCta-text {
    font-size: 72.25px;
  }
}
@media (min-width: 992px) {
  .HeroCta-text {
    font-size: 100px;
  }
}
@media (min-width: 1300px) {
  .HeroCta-text {
    font-size: 100px;
  }
}
@media (min-width: 1600px) {
  .HeroCta-text {
    font-size: 117.5555555556px;
  }
}
@media (min-width: 2400px) {
  .HeroCta-text {
    font-size: 138.8888888889px;
  }
}
.HeroCta-marquee {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin: 0;
  white-space: nowrap;
  font-size: 0;
  opacity: 0.2;
  transition: opacity 0.4s;
}
.desktop .HeroCta-marquee {
  opacity: 0.1;
}
a:hover .HeroCta-marquee {
  opacity: 0.98 !important;
}
.HeroCta-marquee-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
  letter-spacing: -0.02em;
  font-weight: 500;
  padding-right: 0.5em;
  display: inline-block;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@media (min-width: 768px) {
  .HeroCta-marquee-text {
    font-size: 72.25px;
  }
}
@media (min-width: 992px) {
  .HeroCta-marquee-text {
    font-size: 100px;
  }
}
@media (min-width: 1300px) {
  .HeroCta-marquee-text {
    font-size: 100px;
  }
}
@media (min-width: 1600px) {
  .HeroCta-marquee-text {
    font-size: 117.5555555556px;
  }
}
@media (min-width: 2400px) {
  .HeroCta-marquee-text {
    font-size: 138.8888888889px;
  }
}
.HeroCta-marquee-text span {
  display: inline-block;
  padding-right: 0.5em;
}
html.ready .HeroCta-marquee-text {
  animation-name: marquee-right;
}
html.ready .HeroCta + .HeroCta .HeroCta-marquee-text {
  animation-name: marquee;
}

.HomeHeader {
  overflow-x: hidden;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
@media (min-width: 768px) {
  .HomeHeader {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}
@media (min-width: 1300px) {
  .HomeHeader {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
}
.HomeHeader-aside {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .HomeHeader-aside {
    margin-top: 1.5rem;
  }
}
.HomeHeader-title {
  margin: 0;
  opacity: 0;
  font-weight: 400;
  max-width: 30ch;
  margin-left: auto;
  margin-right: auto;
  font-size: var(--step-10);
}
.HomeHeader-title > div {
  position: relative;
  white-space: nowrap;
}
.HomeHeader-title > div > div {
  position: relative;
  z-index: 3;
  opacity: 0;
}
.HomeHeader-title > div > span {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}
.HomeHeader-title > div > span span {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background-color: #000;
}
.HomeHeader-title > div > span:first-child {
  transform: translateX(-100%);
}
.HomeHeader-title > div > span:last-child {
  left: -8px;
}
.HomeHeader-title > div > span {
  color: rgba(255, 255, 255, 0.15);
}
.HomeHeader-title > div > div {
  color: #fff;
}
@media (min-width: 370px) {
  .HomeHeader-title br {
    display: none;
  }
}
.HomeHeader span {
  display: inline-block;
}
.HomeHeader span span {
  display: inline;
}

.InfoSection {
  padding-top: 8rem;
  padding-bottom: 4rem;
}
@media (min-width: 768px) {
  .InfoSection {
    padding-top: 12rem;
    padding-bottom: 8rem;
  }
}
@media (min-width: 1300px) {
  .InfoSection {
    padding-top: 16rem;
  }
}

.InputRange {
  margin-bottom: 1.5rem;
}
.InputRange-labels {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-weight: 700;
  user-select: none;
  padding-bottom: 1rem;
}
@media (max-width: 767.98px) {
  .InputRange-labels {
    display: none;
  }
}
.InputRange-label {
  flex-grow: 1;
  flex-basis: 0;
  white-space: nowrap;
  text-align: center;
  position: relative;
  text-align: left;
  font-size: 14px;
}
@media (min-width: 1600px) {
  .InputRange-label {
    font-size: 16px;
  }
}
.InputRange-label:not(:first-child):not(:last-child) span {
  display: inline-block;
  transform: translateX(-50%);
}
.InputRange-label:last-child {
  position: absolute;
  right: 0;
  top: 0;
}
.InputRange-text {
  cursor: pointer;
  transition: opacity 0.3s;
}
.desktop .InputRange-text:hover {
  opacity: 0.5;
}
@media (min-width: 768px) {
  .InputRange-select {
    display: none;
  }
}
.InputRange-slider {
  height: 12px;
  position: relative;
}
@media (max-width: 767.98px) {
  .InputRange-slider {
    display: none;
  }
}
.InputRange-slider:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #474747;
  top: 5px;
  left: 0;
}
.InputRange-handle {
  position: relative;
  touch-action: pan-y;
  cursor: pointer;
  width: 0;
  height: 10px;
  top: 6px;
}
.InputRange-handle:after, .InputRange-handle:before {
  content: "";
  display: block;
  position: absolute;
}
.InputRange-handle:after {
  width: 6px;
  height: 6px;
  left: -3px;
  top: -3px;
  border-radius: 50%;
  background-color: currentColor;
}
.InputRange-handle:before {
  width: 18px;
  height: 18px;
  left: -9px;
  top: -9px;
  border-radius: 50%;
  border: 1px solid currentColor;
}

.InputText {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.1;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #474747;
}
@media (min-width: 768px) {
  .InputText {
    flex-wrap: nowrap;
  }
}
.InputText-label {
  flex-shrink: 0;
}
@media (max-width: 767.98px) {
  .InputText-label {
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 768px) {
  .InputText-label {
    width: 10em;
    flex-grow: 0;
    margin-right: 16px;
  }
}
@media (min-width: 992px) {
  .InputText-label {
    margin-right: 32px;
    width: 10em;
  }
}
.InputTextarea {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #474747;
}

.Logo {
  width: 120px;
  height: auto;
  direction: block;
}

.MandatoryNote {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: #666666;
  margin-top: 1rem;
  margin-bottom: 0;
}

#Mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  background-color: #ededed;
  pointer-events: none;
  will-change: opacity;
  opacity: 0;
  z-index: 1029;
}
.page-transitioning--work #Mask {
  z-index: 1031;
}
#Mask.in {
  pointer-events: auto;
}

.WorkBar + .Media {
  background-color: #0d0d0d;
}
.Media-image, .Media-video {
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
}
.Media-image, .Media-video {
  opacity: 0;
  transition: opacity 0.4s;
}
.is-loaded .Media-image, .is-loaded .Media-video {
  opacity: 1;
}
.Media-item {
  margin: 0;
}
.Media-item:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(128, 128, 128, 0.08);
  opacity: 1;
  transition: opacity 0.4s;
}
.Media-item.is-loaded:before {
  opacity: 0;
}
.Media-item + .Media-item {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .Media-item + .Media-item {
    margin-top: 4rem;
  }
}
@media (min-width: 1300px) {
  .Media-col {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-left: 8.3333333333%;
  }
}
.Media--fullWidth.Section {
  padding: 0;
}
.Media--fullWidth .Container {
  padding: 0;
  max-width: 100%;
}
.Media--fullWidth .row {
  margin: 0;
}
.Media--fullWidth .Media-image {
  width: 100%;
}
.Media--fullWidth .Media-item {
  padding-top: 0;
}
.Media--fullWidth .Media-col {
  margin: 0;
  padding: 0;
}
@media (min-width: 1300px) {
  .Media--fullWidth .Media-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0%;
  }
}

.MediaGrid-col {
  margin-bottom: calc(32px / 2);
}
@media (min-width: 768px) {
  .MediaGrid-col {
    margin-bottom: 32px;
  }
}
.MediaGrid-media {
  margin: 0 auto;
}
.MediaGrid-media:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(128, 128, 128, 0.08);
  opacity: 1;
  transition: opacity 0.4s;
}
.MediaGrid-media.is-loaded:before {
  opacity: 0;
}
.MediaGrid-col--mobileMedia .MediaGrid-media {
  max-width: 400px;
}
.MediaGrid-image, .MediaGrid-video {
  width: 100%;
  height: auto;
}
.MediaGrid-image, .MediaGrid-video {
  opacity: 0;
  transition: opacity 0.4s;
}
.is-loaded .MediaGrid-image, .is-loaded .MediaGrid-video {
  opacity: 1;
}

.Muted {
  color: rgba(33, 33, 33, 0.4);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Muted--inverted, .Background--dark .Muted, .Background--darker .Muted, .Background--black .Muted {
  color: rgba(255, 255, 255, 0.4);
}

.NextProject-link {
  overflow: hidden;
  position: relative;
  display: block;
  text-align: center;
  font-weight: 500;
  color: #fff;
  transiton: color 0.3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 16px;
  padding-left: 16px;
}
.NextProject-link:hover {
  color: #212121;
}
@media (min-width: 768px) {
  .NextProject-link {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 992px) {
  .NextProject-link {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1300px) {
  .NextProject-link {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1600px) {
  .NextProject-link {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 2400px) {
  .NextProject-link {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 768px) {
  .NextProject-link {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
@media (min-width: 1300px) {
  .NextProject-link {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}
.NextProject-link:before, .NextProject-link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.NextProject-link:before {
  z-index: 1;
  background-color: #0d0d0d;
}
.NextProject-link:after {
  background-color: inherit;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s;
}
.desktop .NextProject-link:hover:after {
  opacity: 1;
}
.NextProject-label {
  opacity: 0.5;
  padding-right: 0.5em;
}
.NextProject-label, .NextProject-title {
  position: relative;
  z-index: 3;
}
@media (max-width: 767.98px) {
  .NextProject-label, .NextProject-title {
    display: block;
    text-align: left;
  }
}
.NextProject--inverted {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.NextProject--inverted a:hover {
  color: #fff;
}

.Background--dark + .NextProject .NextProject-link:before {
  background-color: #000;
}

.PageHeader {
  overflow: hidden;
  text-align: center;
  padding-top: calc(var(--step-10) * 4);
}
.PageHeader-caption {
  margin: 0;
  font-weight: 600;
  padding-bottom: 1rem;
}
.PageHeader-title {
  margin: 0;
  font-weight: 400;
  padding-bottom: calc(var(--step-10) * 3);
}
.PageHeader-title > * {
  will-change: transform;
}
.PageHeader-text {
  margin: 0;
}
.PageHeader-caption, .PageHeader-title, .PageHeader-text {
  visibility: hidden;
}
.is-visible .PageHeader-caption, .is-visible .PageHeader-title, .is-visible .PageHeader-text {
  visibility: visible;
}

.Polaroid {
  position: relative;
  margin-bottom: 128px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .Polaroid {
    width: 85%;
  }
}
@media (min-width: 1300px) {
  .Polaroid {
    width: 80%;
  }
}
.Polaroid-wrapper {
  position: relative;
  padding-top: 30%;
  margin-top: -30%;
  margin-bottom: -30%;
}
.Polaroid-view {
  position: sticky;
  width: 100%;
  padding-bottom: 120%;
  height: 0;
  top: 50%;
  margin-top: 60%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08), 0px 0px 40px rgba(0, 0, 0, 0.08), 0px 10px 60px rgba(0, 0, 0, 0.08);
}
.Polaroid-body {
  position: absolute;
  top: 50%;
  left: 64px;
  width: 100%;
  transform: translateY(-50%) translateX(100%);
  display: none;
}
@media (min-width: 768px) {
  .Polaroid-body {
    display: block;
  }
}
.Polaroid-thumb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s;
}
.Polaroid-thumb.show {
  opacity: 1;
}
.Polaroid-thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 88%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: -8%;
}
.Polaroid-list {
  position: relative;
  opacity: 0.8;
  filter: grayscale(1) contrast(80%);
}
.Polaroid-list:before, .Polaroid-list:after {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  content: "";
  display: block;
}
.Polaroid-list:before {
  margin-bottom: -180%;
}
.Polaroid-list:after {
  margin-bottom: 60%;
}
.Polaroid-image {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 15%;
  padding-bottom: 15%;
}
@media (min-width: 768px) {
  .Polaroid-image {
    padding-top: 20%;
    padding-bottom: 20%;
  }
}
@media (min-width: 1300px) {
  .Polaroid-image {
    padding-top: 25%;
    padding-bottom: 25%;
  }
}
.Polaroid-image img {
  width: 70%;
  opacity: 0;
  transition: opacity 0.2s;
}
.Polaroid-image img.lazyloaded {
  opacity: 1;
}

.Prose a, body.cke_editable a {
  text-decoration: underline;
}
.Prose hr, body.cke_editable hr {
  visibility: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .Prose hr, body.cke_editable hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1600px) {
  .Prose hr, body.cke_editable hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.Prose blockquote, body.cke_editable blockquote {
  color: #a6a6a6;
}
.Prose blockquote > *:last-child, body.cke_editable blockquote > *:last-child {
  margin-bottom: 0;
}
.Background--dark .Prose blockquote, .Background--dark body.cke_editable blockquote, .Background--darker .Prose blockquote, .Background--darker body.cke_editable blockquote, .Background--black .Prose blockquote, .Background--black body.cke_editable blockquote {
  color: #666666;
}
.Prose > *:first-child, body.cke_editable > *:first-child {
  margin-top: 0;
}
.Prose > *:last-child, body.cke_editable > *:last-child {
  margin-bottom: 0;
}

.Reveal {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s, transform 1s;
}
.Reveal--x {
  transform: translateX(30px) translateY(0);
}
.Reveal.is-shown {
  opacity: 1;
  transform: translate(0, 0);
}
.Media--fullWidth .Reveal {
  transform: translate(0, 0) !important;
}
@media (min-width: 768px) {
  .TextWAside .Reveal:nth-child(even) {
    transition-delay: 0.25s;
  }
}
@media (min-width: 992px) {
  .ActivityPanelList .Reveal:nth-child(even) {
    transition-delay: 0.25s;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .CustomerList .Reveal:nth-child(even) {
    transition-delay: 0.25s;
  }
}
@media (min-width: 992px) {
  .CustomerList .Reveal:nth-child(3n+2) {
    transition-delay: 0.25s;
  }
  .CustomerList .Reveal:nth-child(3n+3) {
    transition-delay: 0.5s;
  }
}
.WorkBar + .Media .Reveal {
  transform: translate(0, 0) !important;
}

.Section {
  padding: 3rem 0;
}
@media (min-width: 768px) {
  .Section {
    padding: 10% 0;
  }
}
@media (min-width: 2400px) {
  .Section {
    padding: 11rem 0;
  }
}
.Section--halfPaddingTop {
  padding-top: 1.5rem;
}
@media (min-width: 768px) {
  .Section--halfPaddingTop {
    padding-top: 5%;
  }
}
@media (min-width: 2400px) {
  .Section--halfPaddingTop {
    padding-top: 5.5rem;
  }
}
.Section--halfPaddingBottom {
  padding-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .Section--halfPaddingBottom {
    padding-bottom: 5%;
  }
}
@media (min-width: 2400px) {
  .Section--halfPaddingBottom {
    padding-bottom: 5.5rem;
  }
}
.Section--noPaddingBottom {
  padding-bottom: 0 !important;
}
.Section--noPaddingTop {
  padding-top: 0 !important;
}
.Section:not(.Background--lighter):not(.Background--light):not(.Background--dark):not(.Background--darker):not(.Media--fullWidth) + .Section:not(.Background--lighter):not(.Background--light):not(.Background--dark):not(.Background--darker):not(.Media--fullWidth), .PageHeader + .Section:not(.Background--lighter):not(.Background--light):not(.Background--dark):not(.Background--darker):not(.Media--fullWidth) {
  padding-top: 0 !important;
}
.Section.Background--lighter + .Section.Background--lighter, .PageHeader.Background--lighter + .Section.Background--lighter {
  padding-top: 0 !important;
}
.Section.Background--light + .Section.Background--light, .PageHeader.Background--light + .Section.Background--light {
  padding-top: 0 !important;
}
.Section.Background--dark + .Section.Background--dark, .PageHeader.Background--dark + .Section.Background--dark {
  padding-top: 0 !important;
}
.Section.Background--darker + .Section.Background--darker, .PageHeader.Background--darker + .Section.Background--darker {
  padding-top: 0 !important;
}

.SiteFooter {
  font-weight: 500;
  position: relative;
  padding-top: 4rem;
  padding-bottom: 1.5rem;
}
html:not(.first-run) .SiteFooter {
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
}
@media (max-width: 991.98px) {
  .SiteFooter {
    text-align: left;
  }
}
.ContactCta + .SiteFooter {
  padding-top: 0;
}
.SiteFooter-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .SiteFooter-container {
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .SiteFooter-container {
    flex-direction: row;
  }
}
.SiteFooter-language {
  display: none !important;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .SiteFooter-language {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .SiteFooter-language {
    margin-right: 28px;
    margin-top: 4px;
  }
}
.SiteFooter-colophon {
  font-weight: 600;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  flex-grow: 1;
}
@media (min-width: 992px) {
  .SiteFooter-colophon {
    flex-shrink: 0;
  }
}
@media (min-width: 1300px) {
  .SiteFooter-colophon {
    font-size: 12px;
  }
}
.SiteFooter-colophon a {
  color: rgba(255, 255, 255, 0.4);
}
.SiteFooter-colophon a:hover {
  text-decoration: underline;
}
.SiteFooter-colophon p {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991.98px) {
  .SiteFooter-colophon p {
    justify-content: center;
  }
}
.SiteFooter-colophon p > span {
  display: inline-block;
}
@media (max-width: 767.98px) {
  .SiteFooter-colophon p > span {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .SiteFooter-colophon p > span:not(:last-child):after {
    content: " – ";
    white-space: pre;
  }
}
@media (max-width: 991.98px) {
  .SiteFooter-colophon {
    order: 2;
    width: 100%;
  }
}
.SiteFooter-social {
  font-size: 15px;
}
.SiteFooter-social a {
  transition: opacity 0.3s;
}
.desktop .SiteFooter-social a:hover {
  opacity: 0.5;
}
@media (max-width: 382px) {
  .SiteFooter-social {
    width: 80%;
  }
}
@media (max-width: 991.98px) {
  .SiteFooter-social {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .SiteFooter-social {
    position: relative;
    top: -0.2em;
  }
}
@media (min-width: 992px) {
  .SiteFooter-social {
    width: 15em;
    margin-left: 32px;
  }
}
@media (min-width: 1200px) {
  .SiteFooter-social {
    width: auto;
  }
}
.SiteFooter-social ul {
  margin: auto;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .SiteFooter-social ul {
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .SiteFooter-social ul {
    justify-content: flex-end;
  }
}
.SiteFooter-social li {
  display: inline-block;
}
@media (max-width: 991.98px) {
  .SiteFooter-social li:not(:last-child) {
    margin-right: 8px;
  }
}
@media (min-width: 768px) {
  .SiteFooter-social li:not(:first-child) {
    margin-left: 8px;
  }
}
@media (min-width: 1300px) {
  .SiteFooter-social li:not(:first-child) {
    margin-left: 16px;
  }
}
.SiteFooter-social a {
  position: relative;
  display: inline-block;
  transition: opacity 0.3s;
  text-decoration: none !important;
}
.SiteFooter-social a:before {
  --scale-x: 0;
  content: "";
  position: absolute;
  bottom: 9%;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  transform-origin: 0 0;
  transform: scaleX(var(--scale-x));
  opacity: 0.8;
  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
}
.desktop .SiteFooter-social a:hover {
  opacity: 0.3;
}
.desktop .SiteFooter-social a:hover:before {
  transform-origin: 100% 0;
  --scale-x: 1;
}
.SiteFooter-social a.is-active:before {
  opacity: 1;
  transform-origin: 100% 0;
  --scale-x: 1;
}

.SiteHeader {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;
  transform: translate3D(0, 0, 0);
  background-color: #ededed;
  text-transform: lowercase;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /*
  &--pinned.SiteHeader--not-top {
    html.page-transitioning-in & {
      transform: translate3D(0,-100%,0);
    }
  }
  */
}
@media (min-width: 768px) {
  .SiteHeader {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.SiteHeader:before {
  position: absolute;
  z-index: 5;
  background-color: #ededed;
  content: "";
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s, height 0.01s linear 1s;
}
html.first-run .SiteHeader:before {
  opacity: 0;
  height: 0;
}
.desktop .SiteHeader {
  position: absolute;
}
.inverted-nav .SiteHeader {
  background-color: #000;
  color: #fff;
}
.inverted-nav .SiteHeader a {
  color: #fff;
}
.inverted-nav .SiteHeader:before {
  background-color: #000;
}
.mobile .SiteHeader, .tablet .SiteHeader {
  transition: transform 0.3s;
}
.mobile .SiteHeader--unpinned, .tablet .SiteHeader--unpinned {
  transform: translate3D(0, -100%, 0);
}
.mobile .SiteHeader--pinned, .tablet .SiteHeader--pinned {
  transform: translate3D(0, 0, 0);
}
html.page-transitioning-out .SiteHeader {
  transition: none !important;
  background-color: transparent !important;
}
html.page-transitioning-out .SiteHeader * {
  transition: none !important;
}
.SiteHeader-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}
.SiteHeader-logo {
  font-size: 0;
}
.SiteHeader-logo-link {
  display: inline-block;
}
.desktop .SiteHeader-logo-link {
  opacity: 1;
  transition: opacity 0.3s;
}
.desktop .SiteHeader-logo-link:hover {
  opacity: 0.3;
}
.SiteHeader-logo-image {
  pointer-events: none;
}
@media (max-width: 767.98px) {
  .SiteHeader-logo-image {
    width: 120px;
    height: 20px;
  }
}
@media (max-width: 767.98px) {
  .SiteHeader-brand {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s, transform 0.4s;
    transform: translateX(0);
  }
  .is-open .SiteHeader-brand {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-10%);
  }
}
.SiteHeader-nav {
  font-weight: 500;
}
.SiteHeader-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.SiteHeader-nav li {
  display: inline-block;
}
.SiteHeader-nav li:not(:last-child) {
  margin-right: 16px;
}
.SiteHeader-nav a {
  position: relative;
  display: inline-block;
  transition: opacity 0.3s;
  text-decoration: none !important;
}
.SiteHeader-nav a:before {
  --scale-x: 0;
  content: "";
  position: absolute;
  bottom: 9%;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  transform-origin: 0 0;
  transform: scaleX(var(--scale-x));
  opacity: 0.8;
  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
}
.desktop .SiteHeader-nav a:hover {
  opacity: 0.3;
}
.desktop .SiteHeader-nav a:hover:before {
  transform-origin: 100% 0;
  --scale-x: 1;
}
.SiteHeader-nav a.is-active:before {
  opacity: 1;
  transform-origin: 100% 0;
  --scale-x: 1;
}
.SiteHeader-nav li.is-active a:before {
  opacity: 1;
  transform-origin: 100% 0;
  --scale-x: 1;
}
@media (max-width: 767.98px) {
  .SiteHeader-nav {
    position: absolute;
    top: 0;
    left: var(--space-s);
    right: var(--space-s);
    bottom: 0;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s, transform 0.4s;
    transform: translateX(10%);
    pointer-events: none;
  }
  .is-open .SiteHeader-nav {
    pointer-events: auto;
    opacity: 1;
    transform: translateX(0);
  }
}
.SiteHeader-toggle {
  cursor: pointer;
  font-weight: 500;
}
@media (min-width: 768px) {
  .SiteHeader-toggle {
    display: none;
  }
}

.Slideshow {
  overflow: hidden;
}
.Slideshow-main {
  position: relative;
}
.Slideshow-images:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  border: 1px solid transparent;
}
.Background .Slideshow-images:before {
  border-color: #ededed;
}
.Background--light .Slideshow-images:before {
  border-color: #ededed;
}
.Background--lighter .Slideshow-images:before {
  border-color: #f7f7f7;
}
.Background--dark .Slideshow-images:before {
  border-color: #0d0d0d;
}
.Background--darker .Slideshow-images:before {
  border-color: #000;
}
.Background--black .Slideshow-images:before {
  border-color: #000;
}
.Slideshow-images--curtain {
  position: relative;
  overflow: hidden;
  height: 0;
}
html.desktop .Slideshow-images--curtain {
  height: auto;
}
.Slideshow-images--curtain .Slideshow-media {
  position: relative;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
  background-color: rgba(128, 128, 128, 0.08);
  transform: translate3D(0, 0, 0);
}
.Slideshow-images--curtain .Slideshow-media:nth-child(1) {
  z-index: 19;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(2) {
  z-index: 18;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(3) {
  z-index: 17;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(4) {
  z-index: 16;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(5) {
  z-index: 15;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(6) {
  z-index: 14;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(7) {
  z-index: 13;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(8) {
  z-index: 12;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(9) {
  z-index: 11;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(10) {
  z-index: 10;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(11) {
  z-index: 9;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(12) {
  z-index: 8;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(13) {
  z-index: 7;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(14) {
  z-index: 6;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(15) {
  z-index: 5;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(16) {
  z-index: 4;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(17) {
  z-index: 3;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(18) {
  z-index: 2;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(19) {
  z-index: 1;
}
.Slideshow-images--curtain .Slideshow-media:nth-child(20) {
  z-index: 0;
}
.Slideshow-images--curtain .Slideshow-media:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3D(100%, 0, 0);
}
.Slideshow-images--curtain .Slideshow-media:not(:first-child) .Slideshow-image {
  transform: translate(-95%, 0);
}
.Slideshow-images--curtain .Slideshow-media.is-hidden {
  transform: translate3D(-100%, 0, 0);
}
.Slideshow-images--curtain .Slideshow-media.is-hidden .Slideshow-image {
  transform: translate3D(95%, 0, 0);
}
.Slideshow-images--curtain .Slideshow-media.is-current {
  transform: translate3D(0, 0, 0) !important;
}
.Slideshow-images--curtain .Slideshow-media.is-current .Slideshow-image {
  transform: translate3D(0, 0, 0) !important;
}
.Slideshow-images--curtain.Slideshow-images--clone {
  position: absolute;
  width: 100%;
  top: 0;
  left: 64px;
  transform: translateX(100%);
}
.Slideshow-images--curtain.Slideshow-images--clone .Slideshow-media {
  cursor: pointer;
}
.Slideshow-images--curtain.Slideshow-images--clone .Slideshow-media:last-child {
  display: none;
}
.Slideshow-images--curtain.Slideshow-images--clone .Slideshow-media:not(:first-child) {
  transform: translate3D(0, -100%, 0);
}
.Slideshow-images--curtain.Slideshow-images--clone .Slideshow-media:not(:first-child) .Slideshow-image {
  transform: translate(0, 95%);
}
.Slideshow-images--curtain.Slideshow-images--clone .Slideshow-media.is-hidden {
  transform: translate3D(0, 100%, 0);
}
.Slideshow-images--curtain.Slideshow-images--clone .Slideshow-media.is-hidden .Slideshow-image {
  transform: translate3D(0, -95%, 0);
}
html.desktop .Slideshow-images--swiper {
  height: 0;
  overflow: hidden;
}
.Slideshow-image {
  width: 100%;
  height: auto;
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.3s;
}
.Slideshow-image.lazyloaded {
  opacity: 1;
}
.Slideshow-pager {
  overflow: hidden;
  line-height: 1.1;
  font-weight: 500;
  transform: translateY(-2px);
}
.Slideshow-numbers {
  position: relative;
  display: inline-block;
  padding-right: 1em;
  transition: transform 0.65s cubic-bezier(0.77, 0, 0.175, 1);
}
.Slideshow-numbers span {
  display: block;
}
.Slideshow-numbers span:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}
.Slideshow-numbers span:nth-child(2) {
  transform: translateY(100%);
}
.Slideshow-numbers[data-page="2"] {
  transform: translateY(-100%);
}
.Slideshow-numbers span:nth-child(3) {
  transform: translateY(200%);
}
.Slideshow-numbers[data-page="3"] {
  transform: translateY(-200%);
}
.Slideshow-numbers span:nth-child(4) {
  transform: translateY(300%);
}
.Slideshow-numbers[data-page="4"] {
  transform: translateY(-300%);
}
.Slideshow-numbers span:nth-child(5) {
  transform: translateY(400%);
}
.Slideshow-numbers[data-page="5"] {
  transform: translateY(-400%);
}
.Slideshow-numbers span:nth-child(6) {
  transform: translateY(500%);
}
.Slideshow-numbers[data-page="6"] {
  transform: translateY(-500%);
}
.Slideshow-numbers span:nth-child(7) {
  transform: translateY(600%);
}
.Slideshow-numbers[data-page="7"] {
  transform: translateY(-600%);
}
.Slideshow-numbers span:nth-child(8) {
  transform: translateY(700%);
}
.Slideshow-numbers[data-page="8"] {
  transform: translateY(-700%);
}
.Slideshow-numbers span:nth-child(9) {
  transform: translateY(800%);
}
.Slideshow-numbers[data-page="9"] {
  transform: translateY(-800%);
}
.Slideshow-numbers span:nth-child(10) {
  transform: translateY(900%);
}
.Slideshow-numbers[data-page="10"] {
  transform: translateY(-900%);
}
.Slideshow-numbers span:nth-child(11) {
  transform: translateY(1000%);
}
.Slideshow-numbers[data-page="11"] {
  transform: translateY(-1000%);
}
.Slideshow-numbers span:nth-child(12) {
  transform: translateY(1100%);
}
.Slideshow-numbers[data-page="12"] {
  transform: translateY(-1100%);
}
.Slideshow-numbers span:nth-child(13) {
  transform: translateY(1200%);
}
.Slideshow-numbers[data-page="13"] {
  transform: translateY(-1200%);
}
.Slideshow-numbers span:nth-child(14) {
  transform: translateY(1300%);
}
.Slideshow-numbers[data-page="14"] {
  transform: translateY(-1300%);
}
.Slideshow-numbers span:nth-child(15) {
  transform: translateY(1400%);
}
.Slideshow-numbers[data-page="15"] {
  transform: translateY(-1400%);
}
.Slideshow-numbers span:nth-child(16) {
  transform: translateY(1500%);
}
.Slideshow-numbers[data-page="16"] {
  transform: translateY(-1500%);
}
.Slideshow-numbers span:nth-child(17) {
  transform: translateY(1600%);
}
.Slideshow-numbers[data-page="17"] {
  transform: translateY(-1600%);
}
.Slideshow-numbers span:nth-child(18) {
  transform: translateY(1700%);
}
.Slideshow-numbers[data-page="18"] {
  transform: translateY(-1700%);
}
.Slideshow-numbers span:nth-child(19) {
  transform: translateY(1800%);
}
.Slideshow-numbers[data-page="19"] {
  transform: translateY(-1800%);
}
.Slideshow-numbers span:nth-child(20) {
  transform: translateY(1900%);
}
.Slideshow-numbers[data-page="20"] {
  transform: translateY(-1900%);
}
.Slideshow-footer {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
html.mobile .Slideshow-footer {
  display: none;
}
.Slideshow-nav {
  display: flex;
}
.Slideshow-button {
  user-select: none;
  display: block;
  transition: opacity 0.3s;
}
.Slideshow-button:first-child {
  transform: scaleX(-1);
  margin-right: 32px;
}
.Slideshow-button svg {
  transition: transform 0.3s, opacity 0.3s;
}
.Slideshow-button:not(.is-disabled) {
  cursor: pointer;
}
html.desktop .Slideshow-button:not(.is-disabled):hover svg {
  opacity: 0.6;
  transform: translateX(5px);
}
.Slideshow-button.is-disabled {
  opacity: 0.18;
}
.Slideshow-progress {
  display: none;
  margin-top: 1rem;
  position: relative;
  height: 2px;
}
.Slideshow-progress:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  opacity: 0.2;
}
.Slideshow-progress span {
  background-color: currentColor !important;
}
html.mobile .Slideshow-progress {
  display: block;
}

.SvgLibrary {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
}

.icon-stroke {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-width: 1.7px;
}
@media (max-width: 767.98px) {
  .icon-stroke {
    stroke-width: 1.5px;
  }
}

.Tabs-item {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.6s, transform 0.6s;
  transform: translateY(20px);
}
.Tabs-item.is-active {
  overflow: visible;
  height: auto;
  opacity: 1;
  transform: translateY(0);
}
.Tabs-content {
  padding-top: 4rem;
}
@media (min-width: 768px) {
  .Tabs-content {
    padding-top: 8rem;
  }
}

.TabsNav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .TabsNav {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }
}
.TabsNav-item {
  text-align: center;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .TabsNav-item {
    display: inline-block;
    margin-right: 1.5rem;
  }
}

.TeamMember-figure {
  position: relative;
  background: #0d0d0d;
  display: block;
  margin: 0;
  overflow: hidden;
}
.TeamMember-image {
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}
.TeamMember-image.lazyloaded {
  opacity: 1;
}
.TeamMember-headings {
  line-height: 1.2;
}
.TeamMember-headings, .TeamMember-title, .TeamMember-description {
  display: inline-block;
  margin-bottom: 0;
}
.TeamSlider {
  position: relative;
}
.TeamSlider .swiper-container {
  overflow: inherit;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome and Opera */
}
.TeamSlider .swiper-wrapper {
  align-items: center;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}
.TeamSlider .swiper-slide {
  margin-right: 30px;
}
@media (min-width: 768px) {
  .TeamSlider .swiper-slide {
    margin-right: 60px;
  }
}
@media (min-width: 992px) {
  .TeamSlider .swiper-slide {
    margin-right: 90px;
  }
}
@media (min-width: 1300px) {
  .TeamSlider .swiper-slide {
    margin-right: 150px;
  }
}
.TeamSlider .swiper-slide:last-child {
  margin-right: 0;
}
.TeamSlider .TeamMember {
  opacity: 0;
  transition: opacity 0.1s;
}
.TeamSlider.is-init .TeamMember {
  opacity: 1;
}
.TeamSlider #team-dharma,
.TeamSlider #team-patrizio,
.TeamSlider #team-valerio,
.TeamSlider #team-giorgio {
  width: 85%;
}
@media (min-width: 768px) {
  .TeamSlider #team-dharma,
  .TeamSlider #team-patrizio,
  .TeamSlider #team-valerio,
  .TeamSlider #team-giorgio {
    width: 56%;
  }
}
@media (min-width: 992px) {
  .TeamSlider #team-dharma,
  .TeamSlider #team-patrizio,
  .TeamSlider #team-valerio,
  .TeamSlider #team-giorgio {
    width: 42%;
  }
}
@media (min-width: 1600px) {
  .TeamSlider #team-dharma,
  .TeamSlider #team-patrizio,
  .TeamSlider #team-valerio,
  .TeamSlider #team-giorgio {
    width: 50%;
  }
}
.TeamSlider #team-michele,
.TeamSlider #team-romina,
.TeamSlider #team-luisa,
.TeamSlider #team-gianmarco {
  width: 70%;
}
@media (min-width: 768px) {
  .TeamSlider #team-michele,
  .TeamSlider #team-romina,
  .TeamSlider #team-luisa,
  .TeamSlider #team-gianmarco {
    width: 42%;
  }
}
@media (min-width: 992px) {
  .TeamSlider #team-michele,
  .TeamSlider #team-romina,
  .TeamSlider #team-luisa,
  .TeamSlider #team-gianmarco {
    width: 34%;
  }
}
@media (min-width: 1600px) {
  .TeamSlider #team-michele,
  .TeamSlider #team-romina,
  .TeamSlider #team-luisa,
  .TeamSlider #team-gianmarco {
    width: 42%;
  }
}

.TextGrid-col {
  margin-bottom: 2rem;
}
.TextGrid-col > :last-child {
  margin-bottom: 0;
}
.TextGrid .row:last-child .TextGrid-col:last-child {
  margin-bottom: 0;
}
.TextGrid .row + .row {
  padding-top: 2rem;
}
@media (min-width: 1300px) {
  .TextGrid .row + .row {
    padding-top: 4rem;
  }
}

.TextWAside-text > :last-child, .TextWAside-aside > :last-child {
  margin-bottom: 0;
}
.TextWAside-aside {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (max-width: 767.98px) {
  .TextWAside-aside {
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) {
  .TextWAside-aside {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 992px) {
  .TextWAside-aside {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.TextWAside-text {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .TextWAside-text {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
@media (min-width: 992px) {
  .TextWAside-text {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .TextWAside--textLarge .TextWAside-text {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 1300px) {
  .TextWAside-text {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-left: 4.1666666667%;
  }
  .TextWAside--textLarge .TextWAside-text {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
.TextWAside--asideFollow .TextWAside-aside {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .TextWAside--asideFollow .TextWAside-text {
    margin-bottom: 2rem;
  }
}
@media (max-width: 767.98px) {
  .TextWAside--asideFollow .row {
    flex-direction: column-reverse;
  }
}

.TextWMedia .row {
  align-items: flex-start;
}
.TextWMedia-text, .TextWMedia-mediaList {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: calc(var(--gutter) * 0.5);
  padding-left: calc(var(--gutter) * 0.5);
}
.TextWMedia-text > :last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .TextWMedia-text {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1300px) {
  .TextWMedia-text {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 8.3333333333%;
  }
}
.TextWMedia--hasMedia .TextWMedia-text {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .TextWMedia--hasMedia .TextWMedia-text {
    margin-bottom: 0;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.TextWMedia-media {
  --margin-left: 0;
  --margin-right: 0;
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
}
@media (max-width: 991.98px) {
  .TextWMedia-media {
    --margin-left: auto;
    --margin-right: auto;
  }
}
.TextWMedia-media:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(128, 128, 128, 0.08);
  opacity: 1;
  transition: opacity 0.4s;
}
.TextWMedia-media.is-loaded:before {
  opacity: 0;
}
.TextWMedia-media + .TextWMedia-media {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .TextWMedia-media + .TextWMedia-media {
    margin-top: 4rem;
  }
}
.TextWMedia--mobileMedia .TextWMedia-media {
  max-width: 400px;
}
@media (min-width: 768px) {
  .TextWMedia--mobileMedia .TextWMedia-media {
    --margin-left: auto;
  }
}
@media (min-width: 768px) {
  .TextWMedia-mediaList {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1300px) {
  .TextWMedia-mediaList {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-left: 8.3333333333%;
  }
  .TextWMedia--reverseOrder .TextWMedia-mediaList {
    margin-left: 8.3333333333%;
  }
}
.TextWMedia-image {
  width: 100%;
  height: auto;
  display: block;
}
.TextWMedia-image, .TextWMedia-video {
  opacity: 0;
  transition: opacity 0.4s;
}
.is-loaded .TextWMedia-image, .is-loaded .TextWMedia-video {
  opacity: 1;
}
.TextWMedia--reverseOrder .TextWMedia-text {
  order: 2;
}
@media (min-width: 768px) {
  .TextWMedia--reverseOrder .TextWMedia-media {
    --margin-left: 0;
    --margin-right: 0;
  }
}
@supports (position: sticky) {
  @media (min-width: 768px) {
    .TextWMedia--stickyText .TextWMedia-text {
      position: sticky;
      top: 100px;
      bottom: 0;
    }
  }
  @media (min-width: 992px) {
    .TextWMedia--stickyText .TextWMedia-text {
      top: 110px;
    }
  }
  @media (min-width: 1300px) {
    .TextWMedia--stickyText .TextWMedia-text {
      top: 120px;
    }
  }
  @media (min-width: 1600px) {
    .TextWMedia--stickyText .TextWMedia-text {
      top: 130px;
    }
  }
}

.Video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
.Video video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.WorkBar {
  background-color: #000;
  position: relative;
}
.WorkBar.is-cloned {
  background-color: transparent;
  position: fixed;
  z-index: 1032;
  width: 100%;
  left: 0;
  opacity: 0;
}
.WorkBar.is-cloned .WorkBar-wrapper {
  background-color: transparent !important;
}
.WorkBar.is-cloned .WorkBar-link {
  visibility: hidden;
}
.WorkBar-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .WorkBar-wrapper .small, .WorkBar-wrapper .MandatoryNote, .WorkBar-wrapper .TextWAside-aside {
    line-height: 1.9 !important;
  }
}
.WorkBar-wrapper--original {
  visibility: hidden;
}
.WorkBar-wrapper--clone {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  transform: translate3D(0, 0, 0);
  z-index: 1029;
}
.is-sticky .WorkBar-wrapper--clone {
  position: fixed;
  transition: transform 0.3s;
}
.no-transition .WorkBar-wrapper--clone {
  transition: none !important;
}
.WorkBar--pinned.is-sticky .WorkBar-wrapper--clone {
  transform: translate3D(0, 58px, 0);
}
@media (min-width: 768px) {
  .WorkBar--pinned.is-sticky .WorkBar-wrapper--clone {
    transform: translate3D(0, 92px, 0);
  }
}
.WorkBar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.WorkBar-container, .WorkBar-col {
  position: static;
}
.WorkBar-text {
  margin: 0;
}
.WorkBar-text--title {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.desktop .WorkBar-text--title {
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .WorkBar-text--link + .WorkBar-text--title {
    padding-right: 24px;
  }
}
.WorkBar-text--link {
  order: 2;
}
@media (max-width: 767.98px) {
  .WorkBar-text--link {
    transform: translateX(0) !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.WorkBar-location {
  opacity: 0.5;
}
@media (max-width: 767.98px) {
  .WorkBar-location {
    display: none;
  }
}
@media (min-width: 768px) {
  .WorkBar-location {
    padding-left: 0.5em;
  }
}
.WorkBar-link {
  font-weight: bold;
  position: relative;
  display: inline-block;
}
@media (max-width: 767.98px) {
  .WorkBar-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .WorkBar-link-label {
    display: none;
  }
  .WorkBar-link-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 768px) {
  .WorkBar-link-label {
    display: inline-block;
    position: relative;
  }
  .WorkBar-link-icon {
    transition: transform 0.3s;
  }
  .desktop .WorkBar-link {
    transition: opacity 0.3s;
  }
  .desktop .WorkBar-link-label:before {
    content: "";
    position: absolute;
    bottom: 7%;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    transform-origin: 0 0;
    transform-origin: 100% 0;
    transform: scaleX(0);
    opacity: 0.8;
    transition: transform 0.3s ease-in-out, opacity 0.3s;
  }
  .desktop .WorkBar-link:hover {
    opacity: 0.3;
  }
  .desktop .WorkBar-link:hover .WorkBar-link-icon {
    transform: translateX(5px);
  }
  .desktop .WorkBar-link:hover .WorkBar-link-label:before {
    transform-origin: 0 0;
    transform: scaleX(1);
  }
}
.WorkBar--inverted {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}
.WorkBar--inverted a {
  color: #fff;
}

.WorkHeader {
  padding-top: 8rem;
  padding-bottom: 2rem;
}
@media (min-width: 375px) {
  .WorkHeader {
    padding-bottom: 4rem;
  }
}
@media (min-width: 768px) {
  .WorkHeader {
    padding-top: 12rem;
    padding-bottom: 8rem;
  }
}
@media (min-width: 1300px) {
  .WorkHeader {
    padding-top: 16rem;
  }
}
@media (max-width: 767.98px) {
  .WorkHeader-aside {
    display: none;
  }
}
.WorkHeader-caption {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.WorkHeader-title {
  margin: 0;
}
.WorkHeader-text {
  margin: 0;
}
.WorkHeader-caption, .WorkHeader-title, .WorkHeader-text {
  visibility: hidden;
}
.is-visible .WorkHeader-caption, .is-visible .WorkHeader-title, .is-visible .WorkHeader-text {
  visibility: visible;
}

.WorkList {
  overflow: hidden;
  padding-top: var(--container-padding);
}
html:not(.first-run) .WorkList {
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
}

.WorkList-item {
  margin-bottom: calc(var(--container-padding) * 3);
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: calc(var(--gutter) * 0.5);
  padding-left: calc(var(--gutter) * 0.5);
}
@media (min-width: 768px) {
  .WorkList-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .WorkList-item .WorkPreview-figure {
    padding-bottom: 80%;
  }
}
.WorkList--homepage .WorkList-item {
  align-self: center;
}
@media (min-width: 768px) {
  .WorkList--homepage .WorkList-item:nth-child(6n+1) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+1) .WorkPreview-figure {
    padding-bottom: 80%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+2) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-left: 8.3333333333%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+2) .WorkPreview-figure {
    padding-bottom: 125%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+3) {
    flex: 0 0 75%;
    max-width: 75%;
    margin-left: 25%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+3) .WorkPreview-figure {
    padding-bottom: 60%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+4) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+4) .WorkPreview-figure {
    padding-bottom: 125%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+5) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: 8.3333333333%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+5) .WorkPreview-figure {
    padding-bottom: 80%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+6) {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .WorkList--homepage .WorkList-item:nth-child(6n+6) .WorkPreview-figure {
    padding-bottom: 60%;
  }
}
.WorkList--homepage {
  padding-top: 0  !important;
}

.WorkList-filters {
  display: block;
  font-weight: 400;
  position: relative;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .WorkList-filters {
    margin-bottom: var(--container-padding);
  }
}
.WorkList-filtersToggle {
  cursor: pointer;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.WorkList-filtersToggle .Icon {
  transform: rotate(90deg);
}
@media (min-width: 768px) {
  .WorkList-filtersToggle {
    display: none;
  }
}

.WorkList-filtersSelected {
  display: inline-block;
  padding-left: 0.5em;
  color: #fff;
  flex-grow: 1;
  transition: opacity 0.3s;
}
.WorkList-filtersSelected.is-hidden {
  opacity: 0;
}

@media (max-width: 767.98px) {
  .WorkList-filtersCollapse {
    height: 0;
    overflow: hidden;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }
  .is-ready .WorkList-filtersCollapse {
    transition: height 0.6s;
  }
}
@media (min-width: 768px) {
  .WorkList-filtersCollapse {
    height: auto !important;
    transition: none;
  }
}

.WorkList-filtersList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .WorkList-filtersList {
    font-size: 15px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 768px) {
  .WorkList-filtersList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.WorkList-filtersList li {
  margin-right: 2em;
}
@media (max-width: 767.98px) {
  .WorkList-filtersList li {
    padding-bottom: 0.25rem;
  }
}
.WorkList-filtersList a {
  color: #666666;
  position: relative;
  display: inline-block;
  transition: opacity 0.3s;
  text-decoration: none !important;
  transition: opacity 0.3s, color 0.3s;
}
.WorkList-filtersList a:before {
  --scale-x: 0;
  content: "";
  position: absolute;
  bottom: 9%;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  transform-origin: 0 0;
  transform: scaleX(var(--scale-x));
  opacity: 0.8;
  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
}
.desktop .WorkList-filtersList a:hover {
  opacity: 0.3;
}
.desktop .WorkList-filtersList a:hover:before {
  transform-origin: 100% 0;
  --scale-x: 1;
}
.WorkList-filtersList a.is-active:before {
  opacity: 1;
  transform-origin: 100% 0;
  --scale-x: 1;
}
.WorkList-filtersList a.is-active {
  color: #fff;
}
.desktop .WorkList-filtersList a:hover {
  opacity: 1;
}
@media (max-width: 767.98px) {
  .WorkList-filtersList a:before {
    height: 1px;
  }
}

.WorkPreview-figure {
  display: block;
  height: 0;
  padding-bottom: 80%;
  position: relative;
  margin: 0;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: 50% 60%;
  transition: transform 1s;
}
.WorkPreview-figure-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  transition: visibility 0s;
  transition-delay: 1s;
  background: #0d0d0d;
}
.is-shown .WorkPreview-figure-inner {
  visibility: visible;
}
.WorkPreview-figure:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0d0d0d;
  transform: scaleX(0);
  transition: transform 0.7s, opacity 0.4s linear 1s;
}
.is-shown .WorkPreview-figure:after {
  transform: scaleX(1);
  opacity: 0;
}
.is-shown .WorkPreview-figure {
  transform: scaleY(1);
}
.WorkPreview-image {
  position: absolute;
  top: -10%;
  left: -10%;
  right: -10%;
  bottom: -10%;
  transform: scale(0.9);
  opacity: 0;
  transition: opacity 0.4s, transform 10s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.is-loaded .WorkPreview-image {
  opacity: 1;
}
.desktop a:hover .WorkPreview-image {
  transform: scale(1.1);
}
.WorkPreview-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: #f00;
  transition: opacity 0.6s;
}
.desktop a:hover .WorkPreview-mask {
  opacity: 0.96;
}
.WorkPreview-marquee {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin: 0;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  transition: opacity 0.6s;
}
.desktop a:hover .WorkPreview-marquee {
  opacity: 0.98;
}
.WorkPreview-marquee-text {
  font-size: 36px;
  letter-spacing: -0.01em;
  font-weight: 400;
  padding-right: 4rem;
  display: inline-block;
  transform: translateX(0) translateZ(0);
  animation: marquee 5s linear infinite;
}
@media (min-width: 768px) {
  .WorkPreview-marquee-text {
    font-size: 72.25px;
  }
}
@media (min-width: 992px) {
  .WorkPreview-marquee-text {
    font-size: 100px;
  }
}
@media (min-width: 1300px) {
  .WorkPreview-marquee-text {
    font-size: 100px;
  }
}
@media (min-width: 1600px) {
  .WorkPreview-marquee-text {
    font-size: 117.5555555556px;
  }
}
@media (min-width: 2400px) {
  .WorkPreview-marquee-text {
    font-size: 138.8888888889px;
  }
}
.WorkPreview-headings {
  line-height: 1.2;
}
.WorkPreview-headings, .WorkPreview-title, .WorkPreview-description {
  display: inline-block;
  margin-bottom: 0;
}
.WorkPreview-title {
  margin-top: calc(var(--gutter) * 0.5);
}
.WorkPreview-description {
  color: #666666;
  font-weight: 500;
  transition-delay: 0.2s;
}

.pace {
  pointer-events: none;
  user-select: none;
  display: none;
}
html.first-run .pace {
  display: block;
}
.page-transitioning--workFilter .pace {
  display: none !important;
}

.pace-inactive {
  display: none !important;
}

.pace .pace-progress {
  background: #fff;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}