.TeamMember {

  $c : &;

  &-figure {
    position: relative;
    background: $color-background-dark;
    display: block;
    margin: 0;
    overflow: hidden;
  }

  &-image {
    width: 100%;
    opacity: 0;
    transition: opacity .3s;
    &.lazyloaded {
      opacity: 1;
    }
  }

  // &-mask {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   opacity: 0;
  //   background-color: #f00;
  //   transition: opacity .6s;
  //   will-change: opacity;
  //   a:hover & {
  //     opacity: .90;
  //   }
  // }

  // &-marquee {
  //   position: absolute;
  //   top: 50%;
  //   left: 0;
  //   transform: translateY(-50%);
  //   margin:0;
  //   white-space: nowrap;
  //   font-size: 0;
  //   opacity: 0;
  //   transition: opacity .6s;
  //   will-change: opacity;
  //   display: none;

  //   a:hover & {
  //     opacity: .4;
  //   }
  //   &-text {
  //     @include ms-responsive(20);
  //     letter-spacing: -0.03em;
  //     font-weight: 700;
  //     padding-right: 4rem;
  //     display: inline-block;
  //     transform: translateX(0);
  //     animation: marquee 5s linear infinite;
  //   }
  // }

  &-headings {
    line-height: 1.2;
  }

  &-headings,
  &-title,
  &-description {
    display: inline-block;
    margin-bottom: 0;
  }

  &-title {
  }
}
