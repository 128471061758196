.TextWAside {

  $c : &;

  &-text,
  &-aside {
    >:last-child {
      margin-bottom: 0;
    }
  }

  &-aside {
    @extend .small;
    @include make-col(12);
    @include media-breakpoint-down(xs) {
      @include spacer-stack(5);
    }
    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }
    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
  }

  &-text {
    @include make-col(12);
    @include media-breakpoint-up(sm) {
      @include make-col(8);
    }
    @include media-breakpoint-up(md) {
      @include make-col(7);
      #{$c}--textLarge & {
        @include make-col(9);
      }
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
      @include make-col-offset(0.5);
      #{$c}--textLarge & {
        @include make-col(9);
      }
    }
  }

  &--asideFollow {
    #{$c}-aside {
      @include spacer-stack(0);
    }
    #{$c}-text {
      @include media-breakpoint-down(xs) {
        @include spacer-stack(5);
      }
    }
    @include media-breakpoint-down(xs) {
      .row {
        flex-direction: column-reverse;
      }
    }
  }

}
