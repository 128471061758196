.WorkBar {

  $c : &;

  $headerHeight-xs : 58px;
  $headerHeight-sm : 92px;

  background-color: $color-background-darker;
  position: relative;

  &.is-cloned {
    background-color: transparent;
    position: fixed;
    z-index: $zindex-fixed + 2;
    width: 100%;
    left: 0;
    opacity: 0;
    #{$c}-wrapper {
      background-color: transparent !important;
    }
    #{$c}-link {
      visibility: hidden;
    }
  }

  &-wrapper {

    @include spacer("p", "y", 3);

    @include media-breakpoint-up(sm) {
      .small {
        line-height: 1.9 !important;
      }
    }

    &--original {
      visibility: hidden;
    }

    &--clone {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      transform: translate3D(0,0,0);
      z-index: $zindex-fixed - 1;
      // used only for mobile and tablet, js driven
      .is-sticky & {
        position: fixed;
        transition: transform .3s;
      }
      // used only for mobile and tablet, js driven
      .no-transition & {
        transition: none !important;
      }
    }
  }

  // used only for mobile and tablet, js driven
  &--pinned.is-sticky .WorkBar-wrapper--clone {
    transform: translate3D(0,$headerHeight-xs,0);
    @include media-breakpoint-up(sm) {
      transform: translate3D(0,$headerHeight-sm,0);
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-container,
  &-col {
    position: static; // override bootstrap cols and base .Container
  }

  &-text {
    margin: 0;
  }

  &-text--title {
    font-weight: 400;
    .desktop & {
      font-weight: 500;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-text--link + &-text--title {
    @include media-breakpoint-down(xs) {
      padding-right: 24px;
    }
  }

  &-text--link {
    order: 2;
    @include media-breakpoint-down(xs) {
      transform: translateX(0) !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &-location {
    opacity: .5;
    @include media-breakpoint-down(xs) {
      display: none;
    }
    @include media-breakpoint-up(sm) {
      padding-left: .5em;
    }
  }

  &-link {
    font-weight: bold;
    position: relative;
    display: inline-block;

    @include media-breakpoint-down(xs) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &-label {
        display: none;
      }

      &-icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @include media-breakpoint-up(sm) {

      &-label {
        display: inline-block;
        position: relative;
      }

      &-icon {
        transition: transform .3s;
      }

      .desktop & {
        transition: opacity .3s;
      }
      .desktop &-label:before {
        content: '';
        position: absolute;
        bottom: 7%;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: currentColor;
        transform-origin: 0 0;
        transform-origin: 100% 0;
        transform: scaleX(0);
        opacity: .8;
        transition: transform .3s ease-in-out, opacity .3s;
      }
      .desktop &:hover {
        opacity: .3;
      }

      .desktop &:hover &-icon {
        transform: translateX(5px);
      }
      .desktop &:hover &-label:before {
          transform-origin: 0 0;
          transform: scaleX(1);

      }

    }

    // &-icon-xs {
    //   @include media-breakpoint-up(sm) {
    //     display: none;
    //   }
    // }

    // &-icon {
    //   @include media-breakpoint-down(xs) {
    //     display: none;
    //   }
    // }

  }

  &--inverted {
    @include font-smoothing("antialiased");
    color: #fff;
    a {
      color: #fff;
    }
  }


}
