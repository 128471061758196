.Container {

  @include make-container();
  max-width: $container-max-width;
  // padding-left: var(--container-padding);
  // padding-right: var(--container-padding);

  // @each $breakpoint in map-keys($grid-breakpoints) {
  //   @include media-breakpoint-up($breakpoint) {
  //     $p : map-get($grid-container-paddings, $breakpoint);
  //     padding-left: $p;
  //     padding-right: $p;
      // max-width: $container-max-width + $p * 2;
  //   }
  // }

  // @include media-breakpoint-up(xxl) {
  //   max-width: 1680px;
  // }

}
