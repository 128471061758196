@mixin ms-responsive($scale, $map : $modularscale) {

  $v : (
    base : map-get($map, 'base'),
    ratio : map-get($map, 'ratio')
  );

  $prop: "font-size";

  #{$prop}: ms($scale, $thread: $v, $settings: $map);

  @each $v, $s in $map {
    @if type-of($v) == number {
      @if unit($v) != '' {
        @media (min-width: $v) {
          #{$prop}: ms($scale, $thread: $v, $settings: $map);
        }
      }
    }
  }

  // @each $breakpoint in map-keys($grid-breakpoints) {
  //   @include media-breakpoint-up($breakpoint) {
  //     font-size: ms($scale, $thread: map-get($grid-breakpoints, $breakpoint));
  //   }
  // }

}

