.Prose {

  a {
    text-decoration: underline;
  }

  hr {
    visibility: hidden;
    @include spacer("m", "y", 2);
    @include media-breakpoint-up(sm) {
     @include spacer("m", "y", 4);
    }
    @include media-breakpoint-up(xl) {
     @include spacer("m", "y", 5);
    }
  }

  blockquote {
    @extend .Heading;
    @extend .Heading--medium;
    @extend .Heading--serif;
    > *:last-child {
      margin-bottom: 0;
    }
    color: $color-text-muted;
    .Background--dark &,
    .Background--darker &,
    .Background--black & {
      color: $color-text-inverted-muted;
    }
  }

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

}
