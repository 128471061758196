.Muted {
  color: rgba($color-text, 0.4);
  @include font-smoothing("antialiased");

  &--inverted,
  .Background--dark &,
  .Background--darker &,
  .Background--black & {
    color: rgba($color-text-inverted, 0.4);
  }
}
