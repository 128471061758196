.WorkList {

  @include hideBeforeFirstRun();

  overflow: hidden;

  // @include spacer("p", "t", 6);
  // @include media-breakpoint-up(sm) {
  //   @include spacer("p", "t", 7);
  // }
  // @include media-breakpoint-up(lg) {
  //   @include spacer("p", "t", 8);
  // }
  padding-top: var(--container-padding);

}

.WorkList-item {
  // @include spacer-stack(6);
  margin-bottom: calc(var(--container-padding) * 3);
  @include make-col-ready();

  @include media-breakpoint-up(sm) {
    @include make-col(6);
    .WorkPreview-figure {
      padding-bottom: calc(4 / 5 * 100%);
    }
  }

  .WorkList--homepage & {
    align-self: center;

    @include media-breakpoint-up(sm) {

      // 1st col every 6
      &:nth-child(6n+1) {
        @include make-col(6);
        .WorkPreview-figure {
          padding-bottom: calc(4 / 5 * 100%);
        }
      }

      // 2nd col every 6
      &:nth-child(6n+2) {
        @include make-col(5);
        @include make-col-offset(1);
        .WorkPreview-figure {
          padding-bottom: calc(5 / 4 * 100%);
        }
      }

      // 3rd col every 6
      &:nth-child(6n+3) {
        @include make-col(9);
        @include make-col-offset(3);
        .WorkPreview-figure {
          padding-bottom: calc(3 / 5 * 100%);
        }
      }

      // 4th col every 6
      &:nth-child(6n+4) {
        @include make-col(5);
        .WorkPreview-figure {
          padding-bottom: calc(5 / 4 * 100%);
        }
      }

      // 5th col every 6
      &:nth-child(6n+5) {
        @include make-col(6);
        @include make-col-offset(1);
        .WorkPreview-figure {
          padding-bottom: calc(4 / 5 * 100%);
        }
      }

      // 6th col every 6
      &:nth-child(6n+6) {
        @include make-col(9);
        .WorkPreview-figure {
          padding-bottom: calc(3 / 5 * 100%);
        }
      }

    }

  }

  @include media-breakpoint-up(md) {
    // @include spacer-stack(7);
  }

  @include media-breakpoint-up(xl) {
    // @include spacer-stack(8);
  }

}

.WorkList--homepage {
  @include spacer("p", "t", 0, true);
}

.WorkList-filters {
  display: block;
  font-weight: 400;
  position: relative;
  @include spacer-stack(6);
  @include media-breakpoint-up(sm) {
    // @include spacer-stack(0);
    margin-bottom: var(--container-padding);
    // top: -4rem;
    // transform: translateY(-75%);
  }
  @include media-breakpoint-up(lg) {
    // top: -6rem;
  }
}

.WorkList-filtersToggle {
  cursor: pointer;
  color: $color-text-inverted-muted;
  // border-bottom: 1px solid darken($color-text-inverted-muted, 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  // @include spacer-stack();

  .Icon {
    transform: rotate(90deg);
  }
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.WorkList-filtersSelected {
  display: inline-block;
  padding-left: .5em;
  color:$color-text-inverted;
  flex-grow: 1;
    transition: opacity .3s;
  &.is-hidden {
    opacity: 0;
  }
}

.WorkList-filtersCollapse {
  @include media-breakpoint-down(xs) {
    height: 0;
    overflow: hidden;
    transition-timing-function: cubic-bezier("easeInOutQuint");
    .is-ready & {
      transition: height .6s;
    }
  }
  @include media-breakpoint-up(sm) {
    height: auto !important;
    transition: none;
  }
}

.WorkList-filtersList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  font-weight: 400;
  @include media-breakpoint-down(xs) {
    font-size: 15px;
    @include spacer("p", "y", 2);
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  li {
    margin-right: 2em;
    @include media-breakpoint-down(xs) {
      @include spacer("p", "b", 1);
    }
  }

  a {
    color: $color-text-inverted-muted;
    @include underlineLink();
    transition: opacity .3s, color .3s;
    &.is-active {
      color: $color-text-inverted;
      // border-bottom: 1px solid currentColor;
    }
    .desktop &:hover {
      opacity: 1;
    }
    @include media-breakpoint-down(xs) {
      &:before {
        height: 1px;
      }
    }
  }
}
