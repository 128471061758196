.InputText {
  display: flex;
  flex-wrap: wrap;
  line-height: $headings-line-height;
  @include spacer-stack(5);
  @include spacer("p", "b", 2);
  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
  }
  border-bottom: 1px solid $border-inverted-grey;

  &-label {
    flex-shrink: 0;
    @include media-breakpoint-down(xs) {
      @include spacer("p", "b", 2);
    }
    @include media-breakpoint-up(sm) {
      width: 10em;
      flex-grow: 0;
      margin-right: 16px;
    }
    @include media-breakpoint-up(md) {
      margin-right: 32px;
      width: 10em;
    }
  }

  &-input {
    @include media-breakpoint-up(sm) {
      // width: 1px;
    }
  }
}
