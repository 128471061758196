.ActivityPanel {

  // $border-grey: tint($color-text-muted, 50%);
  // $border-inverted-grey: shade($color-text-inverted-muted, 40%);


  position: relative;

  &-body {
    position: relative;
    background-color: $color-background;
    z-index: 2;

    .Background--dark & {
      background-color: $color-background-dark;
    }

    .Background--darker & {
      background-color: $color-background-darker;
    }

    // @include spacer("p", "x", 3);
    // @include spacer("p", "y", 4);

    // @media (min-width: map-get($grid-breakpoints, "sm")) {
    //   @include spacer("p", "x", 5);
    //   @include spacer("p", "y", 6);
    // }
  }

  &-title {
    @extend .Heading;
    @extend .Heading--context;
    margin-top: 0 !important;
  }

  &-text {
    @extend .Heading;
    @extend .Heading--bump;
    @extend .Heading--serif;
    margin-top: 0 !important;
    color: $color-text-muted;
    .Background--dark &,
    .Background--darker & {
      color: $color-text-inverted-muted;
    }
  }

  &-list {
    @extend .list-unstyled;
    @extend .Heading;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 1;
    margin-bottom: 0 !important;

    li {
      margin-bottom: .5em;
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(xl) {
      font-size: 16px;
    }
  }

  // &-border {
  //   position: absolute;
  //   z-index: 1;
  //   top: -1px;
  //   left: -1px;
  //   right: -1px;
  //   bottom: -1px;
  //   display: none;

  //   &-top,
  //   &-bottom {
  //     position: inherit;
  //     width: 100%;
  //     height: 100%;
  //     background-color: inherit;
  //     &:before {
  //       content: '';
  //       position: inherit;
  //       top: 0;
  //       left: 0;
  //       right: 0;
  //       bottom: 0;
  //       transition: opacity .3s;
  //     }
  //   }

  //   &-top {
  //     top: 0;
  //     left: 0;
  //     z-index: 2;
  //     &:before {
  //       background-color: $border-grey;
  //     }
  //     .Background--dark &,
  //     .Background--darker & {
  //       &:before {
  //         background-color: $border-inverted-grey;
  //       }
  //     }
  //   }

  //   &-bottom {
  //     z-index: 1;
  //     top: 10px;
  //     left: -10px;
  //     border-top: 0;
  //     border-right: 0;
  //     transition: transform .3s;
  //     &:before {
  //       border: 1px solid $border-grey;
  //       border-top: none;
  //       border-right: none;
  //       background-color: $color-background;
  //     }
  //     .Background--dark &:before {
  //       background-color: $color-background-dark;
  //       border-color: $border-inverted-grey;
  //     }
  //     .Background--darker &:before {
  //       background-color: $color-background-darker;
  //       border-color: $border-inverted-grey;
  //     }
  //   }

  //   &-over {
  //     display: none;
  //     top: 10px;
  //     left: -10px;
  //     background-color: $color-dark;
  //     opacity: 0;
  //     transition: opacity .3s, transform .3s !important;
  //     .Background--dark &,
  //     .Background--darker & {
  //       background-color: $color-white;
  //     }
  //   }
  // }

  // html.desktop & {
  //   &:hover {
  //     .ActivityPanel-border-top,
  //     .ActivityPanel-border-bottom {
  //       &:before {
  //         opacity: 0;
  //       }
  //     }
  //     .ActivityPanel-border-bottom {
  //       transform: translateX(-5px) translateY(5px);
  //     }
  //   }
  // }
}
