.NextProject {

  &-link {
    overflow: hidden;
    position: relative;
    display: block;
    text-align: center;
    font-weight: 500;
    color: #fff;
    transiton: color .3s;
    &:hover {
      color: $color-text;
    }
    @include font-smoothing("antialiased");
    @include spacer("p", "y", 5);
    @include grid-spacer("p", "x", 1);
    @include media-breakpoint-up(sm) {
      @include spacer("p", "y", 7);
    }
    @include media-breakpoint-up(lg) {
      @include spacer("p", "y", 8);
    }
    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:before {
      z-index: 1;
      background-color: $color-background-dark;
    }

    &:after {
      background-color: inherit;
      z-index: 2;
      opacity: 0;
      transition: opacity .3s;
    }
    .desktop &:hover:after {
      opacity: 1;
    }
  }

  &-label {
    opacity: 0.5;
    padding-right: .5em;
  }

  &-label,
  &-title {
    position: relative;
    z-index: 3;
    @include media-breakpoint-only(xs) {

      display: block;
      text-align: left;
    }
  }

  &--inverted {
    @include font-smoothing("antialiased");
    a {
      &:hover {
        color: #fff;
      }
    }
  }

}

.Background--dark + .NextProject {
  .NextProject-link:before {
    background-color: $color-background-darker;
  }
}
