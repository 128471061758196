.ActivityPanelList {
  overflow: hidden;
  padding-top: 2px;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    align-items: stretch;
    row-gap: ceil($grid-gutter-width-base * 4);

    > *[class^="col-"] {
      display: flex;
    }
  }

  .ActivityPanel {
    margin-bottom: ceil($grid-gutter-width-base * 1.5);

    @media (min-width: map-get($grid-breakpoints, "md")) {
      margin-bottom: 0;
      display: flex;
    }
  }
}
