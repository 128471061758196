.Slideshow {

  $c: &;

  $transition-speed : 0.5s;
  $transition-easing: cubic-bezier('easeInOutQuart');
  $image-translate: 95%;
  $max-items : 20;

  overflow: hidden;

  &-main {
    position: relative;
  }

  // fix rendering problem
  &-images {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      border: 1px solid transparent;
      .Background & {
        border-color: $color-background;
      }
      .Background--light & {
        border-color: $color-background-light;
      }
      .Background--lighter & {
        border-color: $color-background-lighter;
      }

      .Background--dark & {
        border-color: $color-background-dark;
      }

      .Background--darker & {
        border-color: $color-background-darker;
      }

      .Background--black & {
        border-color: #000;
      }
    }
  }

  &-images--curtain {

    position: relative;
    overflow: hidden;

    height: 0;
    html.desktop & {
      height: auto;
    }

    // fix stupid rendering bug

    #{$c}-media {
      position: relative;
      transition: transform $transition-speed $transition-easing;
      overflow: hidden;
      background-color: $color-background-placeholder;

      @for $i from 1 through $max-items {
         &:nth-child(#{$i}) {
           z-index: #{$max-items - $i};
         }
      }

      transform: translate3D(0, 0, 0);

      &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translate3D(100%, 0, 0);
        #{$c}-image {
          transform: translate(-#{$image-translate}, 0);
        }
      }

      &.is-hidden {
        transform: translate3D(-100%, 0, 0);
        #{$c}-image {
          transform: translate3D(#{$image-translate}, 0, 0);
        }
      }

      &.is-current {
        transform: translate3D(0, 0, 0) !important;
        #{$c}-image {
          transform: translate3D(0, 0, 0) !important;
        }
      }

    }

    &#{$c}-images--clone {

      position: absolute;
      width: 100%;
      top: 0;
      left: $grid-gutter-width-base * 2;
      transform: translateX(100%);

      #{$c}-media {
        cursor: pointer;
        &:last-child {
          display: none;
        }

        &:not(:first-child) {
          transform: translate3D(0, -100%, 0);
          #{$c}-image {
            transform: translate(0, #{$image-translate});
          }
        }

        &.is-hidden {
          transform: translate3D(0, 100%, 0);
          #{$c}-image {
            transform: translate3D(0, -#{$image-translate}, 0);
          }
        }
      }

    }
  }

  &-images--swiper {
    html.desktop & {
      height: 0;
      overflow: hidden;
    }
  }

  &-image {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: transform $transition-speed $transition-easing, opacity .3s;
    &.lazyloaded {
      opacity: 1;
    }
  }

  &-pager {
    overflow: hidden;
    line-height: 1.1;
    font-weight: 500;
    transform: translateY(-2px);
  }

  &-numbers {
    position: relative;
    display: inline-block;
    padding-right: 1em;
    transition: transform $transition-speed * 1.3 $transition-easing;
    span {
      display: block;
      &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    @for $i from 2 through $max-items {
      span:nth-child(#{$i}) {
        transform: translateY($i * 100% - 100%);
      }
      &[data-page="#{$i}"] {
        transform: translateY(- $i * 100% + 100%);
      }
    }
  }

  &-footer {
    @include spacer("p", "t", 3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    html.mobile & {
      display: none;
    }
  }

  &-nav {
    display: flex;
  }

  &-button {
    user-select: none;
    display: block;
    &:first-child {
      transform: scaleX(-1);
      margin-right: 32px;
    }
    transition: opacity .3s;
    svg {
      transition: transform .3s, opacity .3s;
    }
    &:not(.is-disabled) {
      cursor: pointer;
      html.desktop &:hover {
        svg {
          opacity: .6;
          transform: translateX(5px);
        }
      }
    }
    &.is-disabled {
      opacity: .18;
    }
  }

  &-progress {
    display: none;
    @include spacer("m", "t");
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: currentColor;
      opacity: .2;
    }
    position: relative;
    height: 2px;
    span {
      background-color: currentColor !important;
    }

    html.mobile & {
      display: block;
    }
  }

}
