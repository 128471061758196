.Awards {

  &-text {
    @include make-col(12);
    @include media-breakpoint-up(sm) {
      @include make-col(8);
      @include make-col-offset(4);
    }
    @include media-breakpoint-up(md) {
      @include make-col(7);
      @include make-col-offset(3);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }

  &-title.Heading {
    @include spacer-stack(5);
  }

  &-item {
    @include spacer-stack(0);
    &-text {
      @include media-breakpoint-only(xs) {
        font-size: 14px;
      }
      &--strong {
        font-weight: bold;
      }
      & + &:before {
        content: ' - ';
      }
    }

    &--has-logo {
      @include spacer-stack(3);
    }
  }

  &-logo {
    width: 150px;
    display: block;
    @include media-breakpoint-up(sm) {
      display: inline-block;
      width: 200px;
    }
  }

  &-link {
    text-decoration: none !important;
    @include media-breakpoint-up(sm) {
      display: inline-block;
      line-height: 1.7;
      @include underlineLink();
    }

  }


}
