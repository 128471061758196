h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @include font-smoothing("antialiased");
  @extend .Heading;
  span {
    display: inline-block;
  }
}

h1, .h1 {
  @extend .Heading--main;
}

h2, .h2 {
  @extend .Heading--secondary;
}

h3, .h3 {
  @extend .Heading--medium;
}

h4, .h4 {
  @extend .Heading--small;
}

h5, .h5 {
  @extend .Heading--bump;
}

h6, .h6 {
  @extend .Heading--body;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.text-muted {
  color: $color-text-muted !important;
  .Background--dark &,
  .Background--darker &,
  .Background--black & {
    color: $color-text-inverted-muted !important;
  }
}

small,
.small {
  line-height: 1.5;
  font-size: calc(14 / 16 * 100%);
  font-weight: 500;
  // font-weight: 400;
  @include media-breakpoint-up(xl) {
    font-size: 15px;
    // font-weight: 300;
  }
}

strong,
.strong,
b {
  font-weight: 600;
}

pre {
  padding: .2rem .5rem;
  background-color: #eee;
  color: #666;
}
