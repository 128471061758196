.HeroCta {

  $c: &;
  // background: #ff0;

  & + & {
    padding-top: 0 !important;
    @include spacer("m", "t", 3, false, -1);

    @include media-breakpoint-up(sm) {
      @include spacer("m", "t", 4, false, -1);
    }

    @include media-breakpoint-up(lg) {
      @include spacer("m", "t", 5, false, -1);
    }
  }

  @include hideBeforeFirstRun();
  @include spacer("p", "y", 3);
  @include media-breakpoint-up(sm) {
    @include spacer("p", "y", 4);
  }

  @include media-breakpoint-up(lg) {
    @include spacer("p", "y", 5);
  }

  overflow: hidden;
  line-height: 1;

  &-link {
    overflow: hidden;
    position: relative;
    display: block;
    color: #fff;
    text-align: center;
  }

  &-text {
    @include ms-responsive(18);
    font-weight: 500;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    visibility: hidden;
  }

  &-marquee {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin:0;
    white-space: nowrap;
    font-size: 0;
    opacity: 0.2;

    .desktop & {
      opacity: 0.1;
    }
    // .Background--darker & {
    //   opacity: 0.065;
    //   .desktop & {
    //     opacity: 0.04;
    //   }
    // }
    transition: opacity .4s;

    a:hover & {
      opacity: 0.98 !important;
    }

    &-text {
      @include font-smoothing("antialiased");
      @include ms-responsive(18);
      letter-spacing: -0.02em;
      font-weight: 500;
      padding-right: 0.5em;

      span {
        display: inline-block;
        padding-right: 0.5em;
      }

      display: inline-block;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      html.ready & {
        animation-name: marquee-right;
      }

      #{$c} + #{$c} & {
        html.ready & {
          animation-name: marquee;
        }
      }
    }
  }

}
