.SiteHeader {

  $c: &;

  @include font-smoothing("antialiased");

  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-fixed;
  transform: translate3D(0,0,0);
  background-color: $color-background;
  text-transform: lowercase;

  @include spacer("p", "y", 3);
  @include media-breakpoint-up(sm) {
    @include spacer("p", "y", 5);
  }

  &:before {
    position: absolute;
    z-index: 5;
    background-color: $color-background;
    content: '';
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s, height 0.01s linear 1s;
  }

  html.first-run & {
    &:before {
      opacity: 0;
      height: 0;
    }
  }

  .desktop & {
    position: absolute;
  }

  .inverted-nav & {
    background-color: $color-background-darker;
    color: #fff;
    a {
      color: #fff;
    }

    &:before {
      background-color: $color-background-darker;
    }
  }


  .mobile &,
  .tablet & {

    transition: transform .3s;

    &--unpinned {
      transform: translate3D(0,-100%,0);
    }

    &--pinned {
      transform: translate3D(0,0,0);
    }

  }

  // a.is-active {
  //   cursor: default;
  //   pointer-events: none;
  // }

  // when transition is running and header is pinned:
  /*
  &--pinned#{&}--not-top {
    html.page-transitioning-in & {
      transform: translate3D(0,-100%,0);
    }
  }
  */

  html.page-transitioning-in & {
    // pointer-events: none;
  }

  html.page-transitioning-out & {
    transition: none !important;
    background-color: transparent !important;
    * {
      transition: none !important;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
  }

  &-logo {

    font-size: 0;

    &-link {
      display: inline-block;
      .desktop & {
        opacity: 1;
        transition: opacity .3s;
        &:hover {
          opacity: .3;
        }
      }
    }

    &-image {
      pointer-events: none;

      @include media-breakpoint-down(xs) {
        width: 120px;
        height: 20px;
      }
    }


  }

  &-brand {
    @include media-breakpoint-down(xs) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 1;
      transition: opacity .3s, transform .4s;
      transform: translateX(0);
      .is-open & {
        opacity: 0;
        pointer-events: none;
        transform: translateX(-10%);
      }
    }

  }

  &-nav {

    // font-size: $small-font-size;
    font-weight: 500;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    a {
      @include underlineLink();
    }

    li.is-active a {
      @include underlineLinkActive();
    }

    @include media-breakpoint-down(xs) {
      position: absolute;
      top: 0;
      left: map-get($grid-container-paddings, xs);
      right: map-get($grid-container-paddings, xs);
      bottom: 0;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0;
      transition: opacity .3s, transform .4s;
      transform: translateX(10%);
      pointer-events: none;
      .is-open & {
        pointer-events: auto;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &-toggle {
    cursor: pointer;
    font-weight: 500;
    // font-size: 14px;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

}
