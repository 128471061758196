@mixin underlineLink() {
  position: relative;
  display: inline-block;
  transition: opacity .3s;
  text-decoration: none !important;
  &:before {
    --scale-x: 0;
    content: '';
    position: absolute;
    bottom: 9%;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    transform-origin: 0 0;
    transform: scaleX(var(--scale-x));
    opacity: .8;
    transition: transform .4s cubic-bezier(easeInOutQuint), opacity .3s;
  }
  .desktop &:hover {
    opacity: .3;
    &:before {
      transform-origin: 100% 0;
      --scale-x: 1;
    }
  }
  &.is-active {
    &:before {
      opacity: 1;
      transform-origin: 100% 0;
      --scale-x: 1;
    }
  }
}

@mixin underlineLinkActive() {
  &:before {
    opacity: 1;
    transform-origin: 100% 0;
    --scale-x: 1;
  }
}

// put this in a mixin because of tabs navigation crazy logic
@mixin ButtonActive() {

  background-color: $color-basilico;
  border-color: $color-basilico;
  color: $color-basilico;

  .Button-text {
    background-color: $color-background;
    .Background--dark & {
      background-color: $color-background-dark;
    }
    .Background--darker & {
      background-color: $color-background-darker;
    }
    .Background--black & {
      background-color: #000;
    }
  }

  &:before {
    transform: translate(-2px, 2px);
  }
}

@mixin hideBeforeFirstRun() {
  html:not(.first-run) & {
    padding: 0 !important;
    margin: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    visibility: hidden !important;
  }
}
