.TextGrid {

  $c: &;

  &-col {
    >:last-child {
      margin-bottom: 0;
    }
    @include spacer-stack(5);
  }

  .row {
    &:last-child {
      #{$c}-col:last-child {
        margin-bottom: 0;
      }
    }
  }

  .row + .row {
    @include spacer("p", "t", 5);
    @include media-breakpoint-up(lg) {
      @include spacer("p", "t", 6);
    }
  }
}
