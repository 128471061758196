.SiteFooter {

  @include hideBeforeFirstRun();

  font-weight: 500;
  position: relative;
  @include spacer("p", "t", 6);
  @include spacer("p", "b", 4);
  @include media-breakpoint-down(sm) {
    text-align: left;
  }
  @include media-breakpoint-up(sm) {
    // @include spacer("p", "y", 5);
  }
  @include media-breakpoint-up(md) {
    // @include spacer("p", "y", 6);
  }

  .ContactCta + & {
    padding-top: 0;
  }


  &-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media-breakpoint-only(sm) {
      justify-content: center;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &-language {
    display: none !important;
    // align-items: center;
    @include spacer-stack(4);
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @include media-breakpoint-up(md) {
      margin-right: 28px;
      margin-top: 4px;
    }
  }

  &-colophon {
    font-weight: 600;
    font-size: 12px;
    color: rgba($color-text-inverted, 0.4);
    flex-grow: 1;
    @include media-breakpoint-up(md) {
      flex-shrink: 0;
    }
    @include media-breakpoint-up(lg) {
      font-size: 12px;
    }
    a {
      color: rgba($color-text-inverted, 0.4);
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }
    }
    p > span {
      display: inline-block;
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      @include media-breakpoint-up(sm) {
        &:not(:last-child):after {
          content: ' – ';
          white-space: pre;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      order: 2;
      width: 100%;
    }
  }


  &-social {
    a {
      transition: opacity .3s;
      .desktop & {
        &:hover {
          opacity: .5;
        }
      }
    }
    font-size: 15px;
    @media (max-width: 382px) {
      width: 80%;
    }
    @include media-breakpoint-down(sm) {
      @include spacer-stack(3);
    }
    @include media-breakpoint-up(sm) {
      position: relative;
      top: -0.2em;
    }
    @include media-breakpoint-up(md) {
      width: 15em;
      margin-left: 32px;
    }
    @media (min-width: 1200px) {
      width: auto;
    }
    ul {
      margin: auto;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include media-breakpoint-up(sm) {
        justify-content: center;
      }
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
      // justify-content: center;
    }
    li {
      display: inline-block;
      @include media-breakpoint-down(sm) {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
      &:not(:first-child) {
        @include media-breakpoint-up(sm) {
          margin-left: 8px;
        }
        @include media-breakpoint-up(lg) {
          margin-left: 16px;
        }
      }
    }
    a {
      @include underlineLink();
    }
  }
}
