.TabsNav {
  margin:0;
  padding:0;
  list-style-type: none;
  display: flex;
  justify-content: center;

  // @include spacer-stack(6);

  // @include media-breakpoint-up(xl) {
  //   @include spacer-stack(7);
  // }

  @include media-breakpoint-down(xs) {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }

  &-item {
    text-align: center;

    @include spacer-stack(4);

    @include media-breakpoint-up(sm) {
      display: inline-block;
      @include spacer-inline(4);
    }
  }

  // @include media-breakpoint-up(sm) {
  //   &:not(.is-active) {
  //     .TabsNav-item:first-child .Button {
  //       @include ButtonActive();
  //     }
  //   }
  // }

}
