.Button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  text-decoration: none !important;
  outline: none;
  border: none;
  color: currentColor;
  background: none;
  appearance: none;
  padding: 0;
  line-height: 1;
  font-size: $small-font-size;
  transition: border-color .3s, background .3s, color .3s;
  border-radius: 2em;
  padding: 1.2em 2em;
  min-width: 200px;
  background-color: #000;
  border: 2px solid rgba(255,255,255, 0.3);

  & + & {
    margin-left: 32px;
  }

  &:hover {
    border: 2px solid rgba(255,255,255, 0.5);
  }

  .modal & {
    background: transparent;
    border-color: currentColor;
  }

  &.is-active {
    border-color: $color-background;
    background: $color-background;
    color: #000;
    // @include ButtonActive();
  }


  &--blockOnMobile {
    @include media-breakpoint-down(xs) {
      display: block;
      width: 100%;
      .Button-text {
        display: block;
      }
    }
  }
}


.Button--language {
  text-transform: none;
  border-color: #3e3e3e;
  opacity: 1 !important; // because SiteFooter has opacity on links

  &:before {
    top: 5px;
    left: -5px;
    border-color: inherit;
  }

  .Button-text {
    padding: 0.4em 1.3em 0.3em;
    min-width: 0;
  }

  .Button-ballon {
    display: block;
    position: absolute;
    bottom: -3px;
    left: 14px;
    transform: translateY(100%);
    width: 14px;
    height: 11px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform .5s;
    transform: translateZ(0);
  }


  polygon {
    transition: fill .3s;
    fill: #3e3e3e;
    &:last-child {
      fill: #000;
    }
  }

  &:hover svg,
  &:hover:before {
    transform: translate(-2px, 2px)  translateZ(0);
  }

  &:hover polygon {
    fill: $color-basilico !important;
  }

}
